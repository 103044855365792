import { appData } from "../app_data";
import { PartnersList } from "./Sections";

const Below = () => {
    return (
        <footer>

            <div className="container-01">

                <div className="container d-flex flex-column align-items-center">

                    <span className="h1">Contact Us for your support needs</span>

                    <p>Submit a ticket and we will get back to you soon.</p>

                    <a href={appData.links.contact} className="btn btn-large btn-blue">Contact Us</a>

                </div>

            </div>

            <div className="container-02 pb-0">

                <div className="container">

                    <div className="content-01">

                        <a href="index.html" className="content-img logo"><img src={appData.logoURL} alt={appData.appName} /></a>

                        <p>{appData.appName} is a company that offers web hosting services. Among the most outstanding services are Website Hosting, Domain Registration, VPS Servers and Dedicated Servers.</p>

                        <span className="font-bold mb-5">Social Network</span>

                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>

                        </nav>

                        <span className="font-bold mt-5">Contact Us</span>

                        <address>
                            {/* <p>Phone: {appData.phone}</p> */}
                            <p>Email: <a href={`mailto:${appData.email}`}>{appData.email}</a></p>
                        </address>

                        {/* <a href="/contact-us" className="btn btn-transparent mt-5">Contact Us</a> */}

                    </div>

                    <div className="content-02">

                        <span className="font-bold">Hosting & Website</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4 mb-5">
                                <li><a href={appData.links.sharedHosting}><i className="cci-share-03"></i>Shared Hosting</a></li>
                                {/* <li><a href={appData.links.sharedHosting}><i className="cci-server-secure-01"></i>Private Hosting</a></li> */}
                                <li><a href={appData.links.siteBuilder}><i className="cci-design-01"></i>Website Builder</a></li>
                                {/* <li><a href={appData.links.sharedHosting}><i className="cci-online-shop-03"></i>Shop Builder</a></li> */}
                                <li><a href={appData.links.wordpressToolkit}><i className="cci-wordpress-01"></i>Wordpress Toolkit</a></li>
                                <li><a href={appData.links.sharedHosting}><i className="cci-joomla-01"> </i>Joomla Hosting</a></li>
                            </ul>

                        </nav>

                        <span className="font-bold">Services</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4">
                                <li><a href={appData.links.siteBuilder}><i className="cci-design-01"></i>Web Design Services</a></li>
                            </ul>

                        </nav>

                    </div>

                    <div className="content-03">

                        <span className="font-bold">Domain & SSL</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4 mb-5">
                                <li><a href={appData.links.registerDomain}><i className="cci-www-01"></i>Register Domain</a></li>
                                <li><a href={appData.links.transferDomain}><i className="cci-transfer-02"></i>Transfer Domain</a></li>
                                <li><a href={appData.links.ssl}><i className="cci-ssl-01"></i>SSL Certificate</a></li>
                            </ul>

                        </nav>

                        {/* <span className="font-bold">Server</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4">
                                <li className="bg-text-hover"><a href="vps-hosting.html"><i className="cci-vps-04"></i>VPS Hosting</a></li>
                                <li className="bg-text-hover"><a href="cloud-computing.html"><i className="cci-server-12"></i>Cloud Computing</a></li>
                                <li className="bg-text-hover"><a href="dedicated-server.html"><i className="cci-server-09"></i>Dedicated Server</a></li>
                                <li className="bg-text-hover"><a href="pay-per-use.html"><i className="cci-money-01"></i>Pay Per Use</a></li>
                            </ul>

                        </nav> */}

                    </div>

                    <div className="content-04">

                        <span className="font-bold">Client Area</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4 mb-5">
                                <li><a href={appData.links.login}><i className="cci-user-01"></i>Login</a></li>
                                <li><a href={appData.links.register}><i className="cci-user-02"></i>Register</a></li>
                                <li><a href="/"><i className="cci-target-02"></i>About Us</a></li>
                            </ul>

                        </nav>

                        {/* <span className="font-bold">Legal</span>

                        <nav className="mt-3 nav-in-column">

                            <ul className="list-in-column mt-4">
                                <li className="bg-text-hover"><a href={appData.links.termsAndConditions}><i className="cci-newspaper-02"></i>Terms of Service</a></li>
                                <li className="bg-text-hover"><a href={appData.links.privacyPolicy}><i className="cci-newspaper-01"></i>Privacy Policy</a></li>
                            </ul>

                        </nav> */}

                    </div>

                </div>

            </div>

            <div className="container-03">

                <div className="container">

                    <div className="content-left">

                        {<PartnersList />}

                    </div>

                    <div className="content-right">

                        <ul className="list-icons list-in-row list-in-row-right">
                            <li><i className="cci-mastercard-pay-01"></i></li>
                            <li><i className="cci-visacard-pay-01"></i></li>
                            <li><i className="cci-maestro-pay-01"></i></li>
                            <li><i className="cci-visacard-pay-02"></i></li>
                            <li><i className="cci-wirecard-pay-01"></i></li>
                            <li><i className="cci-paypal-pay-01"></i></li>
                        </ul>

                    </div>

                </div>

            </div>

            <div className="container-04">

                <div className="container">

                    <div className="content-left">

                        <p>Copyright © 2022 {appData.appName} Web Hosting Services</p>

                    </div>

                    <nav className="content-right nav-in-line">

                        <ul>
                            <li className="bg-text-hover"><a href={'/termsandconditions/termsofservice'}>Terms Of Service</a></li>
                            <li className="bg-text-hover"><a href={'/termsandconditions/privacypolicy'}>Privacy Policy</a></li>
                        </ul>

                    </nav>

                </div>

            </div>

        </footer>
    )
}

export default Below;