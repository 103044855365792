import { Partners } from "../../theme/Sections";

const WebDesign = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Design Services</span>

                        <span className="h2">We create your website or shop for you</span>

                        <p>We will build your website or system from scratch with a modern, beautiful and attractive design. In addition, we will provide you with a complete customization documentation.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">Choose Service</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/website-builder-06.svg" alt="Website Builder Banner" /></div>

                </div>

            </div>

        </div>

        <section id="plans">

            <div className="container">

                <h1 className="h1">Choose The Service You'd Like Us To Do Below</h1>

                <p className="txt-large">Let's create your store or website from scratch with a modern, attractive and responsive design.</p>

                <div className="box box-info box-two">

                    <div className="box-item">

                        <i className="cci-design-01"></i>

                        <span>Website Build</span>

                        <p>Let’s create a site in HTML, CSS and JS for you. If you want us to migrate the site or build directly on wordpress or other framework, you pay a small fee for it.</p>

                        <a href="contact-us.html" className="btn btn-transparent-black">Request Quote</a>

                    </div>

                    <div className="box-item">

                        <i className="cci-code-01"></i>

                        <span>Shop & Systems</span>

                        <p>We will create your online store or system as you wish. Our programmers are experienced and will build a secure system for your business with warranty.</p>

                        <a href="contact-us.html" className="btn btn-transparent-black">Request Quote</a>

                    </div>

                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">Why Hire Design Service With Us ?</h2>

                <p className="txt-large">Get a 90-day warranty with us when you hire a design or programming service</p>

                <div className="box box-info box-four">

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-code-01"></i>

                            <span className="h2">Organized Code</span>

                        </div>

                        <p>Our programmers have experience in the area for years. The site code will be well organized and clean.</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-design-01"></i>

                            <span className="h2">Modern Design</span>

                        </div>

                        <p>Our designer is experienced and creative. Your website design will be modern, attractive and stylish.</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-responsive-01"></i>

                            <span className="h2">Responsive</span>

                        </div>

                        <p>The design of the system or website will be adaptable to celualres and smaller screens.</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-complain-01"></i>

                            <span className="h2">Quality Assurance</span>

                        </div>

                        <p>We will perform a quality and safety test on your system or website before we deliver it to you.</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What is Shared Hosting and why I should choose?</div>

                                <div className="box-item-body"><p>Shared web hosting service is a web hosting service where many websites reside on one web server connected to the Internet. This is generally the most economical option for hosting, as the overall cost of server maintenance is spread over many customers. By choosing shared hosting, the website will share a physical server with one or more other websites. The service must include system administration since it is shared by many users; this is a benefit for users who do not want to deal with it, but a hindrance to power users who want more control. In general shared hosting will be inappropriate for users who require extensive software development outside what the hosting provider supports. Almost all applications intended to be on a standard web server work fine with a shared web hosting service. But on the other hand, shared hosting is cheaper than other types of hosting such as dedicated server hosting.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a VPS server and why should I choose?</div>

                                <div className="box-item-body"><p>A virtual private server (VPS) is a virtual machine sold as a service by an Internet hosting service. The virtual dedicated server (VDS) also works in a virtualized environment but some CPU cores are dedicated for it, which is not the case with VPS. A virtual private server runs its own copy of an operating system (OS), and customers may have superuser-level access to that operating system instance, so they can install almost any software that runs on that OS. For many purposes it is functionally equivalent to a dedicated physical server and, being software-defined, can much more easily be created and configured. A virtual server costs much less than an equivalent physical server. However, as virtual servers share the underlying physical hardware with other VPSes, performance may be lower, depending on the workload of any other executing virtual machines</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a Cloud Computing and why I should choose?</div>

                                <div className="box-item-body"><p>Cloud computing is the on-demand availability of computer system resources, especially data storage (cloud storage) and computing power, without direct active management by the user. The term is generally used to describe data centers available to many users over the Internet. Large clouds, predominant today, often have functions distributed over multiple locations from central servers. If the connection to the user is relatively close, it may be designated an edge server. Clouds may be limited to a single organization (enterprise clouds), or be available to multiple organizations (public cloud). Cloud computing relies on sharing of resources to achieve coherence and economies of scale.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a Dedicated Server and why I should choose?</div>

                                <div className="box-item-body"><p>A dedicated hosting service, dedicated server, or managed hosting service is a type of Internet hosting in which the client leases an entire server not shared with anyone else. This is more flexible than shared hosting, as organizations have full control over the server(s), including choice of operating system, hardware, etc. There is also another level of dedicated or managed hosting commonly referred to as complex managed hosting. Complex Managed Hosting applies to both physical dedicated servers, Hybrid server and virtual servers, with many companies choosing a hybrid (combination of physical and virtual) hosting solution.</p></div>

                            </div>

                        </div>

                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">

                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>);
}

export default WebDesign;