import Layout from "./theme/Index";
import { Navigate, useRoutes } from "react-router-dom";
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import About from "./pages/about/About";
import Hosting from './pages/hosting';
//-------------------------------------------------------
import SharedHosting from './pages/hosting/SharedHosting';
import JoomlaHosting from './pages/hosting/JoomlaHosting';
import WordpressHosting from './pages/hosting/WordpressHosting';
import PrivateHosting from './pages/hosting/PrivateHosting';
import WebsiteBuilder from './pages/hosting/WebsiteBuilder';
import ShopBuilder from './pages/hosting/ShopBuilder';
//-------------------------------------------------------
import Services from './pages/services';
import WebDesign from './pages/services/WebDesign';
//-------------------------------------------------------
import DomainSSL from './pages/domain_ssl/index';
import RegisterDomain from "./pages/domain_ssl/RegisterDomain";
import TransferDomain from "./pages/domain_ssl/TransferDomain";
import SSL from "./pages/domain_ssl/SSL";
//-------------------------------------------------------
import TermsAndConditions from './pages/termsandconditions';
import PrivacyPolicy from "./pages/termsandconditions/PrivacyPolicy";
import TermsOfService from "./pages/termsandconditions/TermsOfService";
//-------------------------------------------------------

const Main = () => {
  const Routes = () => useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "/", element: <Home /> },
        { path: "/contact_us", element: <Contact /> },
        { path: "/about_us", element: <About /> },
      ],
    },
    {
      path: "/hosting",
      element: <Hosting />,
      children: [
        { element: <Navigate to="/hosting" replace /> },
        { path: "/hosting/shared", element: <SharedHosting /> },
        { path: "/hosting/wordpress", element: <WordpressHosting /> },
        { path: "/hosting/joomla", element: <JoomlaHosting /> },
        { path: "/hosting/shopbuilder", element: <ShopBuilder /> },
        { path: "/hosting/websitebuilder", element: <WebsiteBuilder /> },
        { path: "/hosting/private", element: <PrivateHosting /> },
      ]
    },
    {
      path: "/services",
      element: <Services />,
      children: [
        { element: <Navigate to="/services" replace /> },
        { path: "/services/webdesign", element: <WebDesign /> },
      ]
    },
    {
      path: "/domain_ssl",
      element: <DomainSSL />,
      children: [
        { element: <Navigate to="/domain_ssl" replace /> },
        { path: "/domain_ssl/registerdomain", element: <RegisterDomain /> },
        { path: "/domain_ssl/transferdomain", element: <TransferDomain /> },
        { path: "/domain_ssl/SSL", element: <SSL /> },
      ]
    },
    {
      path: "/termsandconditions",
      element: <TermsAndConditions />,
      children: [
        { element: <Navigate to="/termsandconditions" replace /> },
        { path: "/termsandconditions/termsofservice", element: <TermsOfService /> },
        { path: "/termsandconditions/privacypolicy", element: <PrivacyPolicy /> },
      ]
    },
  ]);

  return (
    <Routes />
  );
}

export default Main;
