import { useEffect } from "react";
import { topData, testimonials } from "../../elements/sliders/data";
import { Slider } from "../../elements/sliders/slider";
import OwlCarousel from "react-owl-carousel";
import { appData } from "../../app_data";
import { Partners } from "../../theme/Sections";

const Home = () => {

    const customerSliderOptions = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: false,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            900: {
                items: 1,
                nav: false
            },
            1000: {
                items: 3,
                nav: false,
                loop: false
            }
        }
    };
// eslint-disable-next-line 
    const partnersSliderOptions = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        center: false,
        autoplayTimeout: 2000,
        autoplayHoverPause: false,
        dots: false,
        responsive: {
            0: {
                items: 2,
                nav: false
            },
            900: {
                items: 5,
                nav: false
            },
            1000: {
                items: 7,
                nav: false,
                loop: false
            }
        }
    };

    useEffect(() => {
        new Slider({
            active: true,
            sliderParent: ".slider-homepage",
            sliderChild: ".slider-item",
            sliderActive: ".slider-active",
            sliderSubParent: ".slider-items",
            sliderControl: ".slider-control",
            sliderBtnActive: "slider-btn-active",
            control: true,
            autoPlay: {
                active: true,
                time: 3000,
            },
            time: 350,
        });

    }, []);


    return (
        <main>
            <div className="slider slider-homepage">
                {
                    topData.map((item, index) => (
                        <div className={item.className} key={index}>
                            <div className="container">
                                <div className="content-left">
                                    <div className="content-text">
                                        <span className="h3 font-light">{item.title}</span>
                                        <span className="h2">{item.subtitle}</span>
                                        <p>{item.content}</p>
                                        <div className="btn-group">
                                            <a className="btn btn-blue" href={item.link1}>{item.text1}</a>
                                            <a className="btn btn-transparent" href={item.link2}>{item.text2}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-right">
                                    <div className="content-img"><img src={item.img.src} alt={item.img.alt} /></div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <section>

                <div className="container">

                    <h1 className="h1">Our Most Popular Hosting Products</h1>

                    <p>{appData.appName} has the best infrastructure and the best price for your web hosting needs.</p>

                    <div className="box box-info">

                        <div className="box-item">

                            <i className="cci-server-04"></i>

                            <span>Shared Hosting</span>

                            <p>An inactive website means that there is a high probability that you will miss out on opportunities to interact with a customer (or potential customer). That's why we offer a 99.9% guaranteed uptime service.</p>

                            <a href={appData.links.sharedHosting} className="btn btn-transparent-black">See all plans</a>

                        </div>

                        <div className="box-item">

                            <i className="cci-wordpress-01"></i>

                            <span>Wordpress Hosting</span>

                            <p>Create a Wordpress site that you will love. 30-day guarantee. Get a fast and reliable Wordpress hosting with 30-day guarantee of your money back to your Wordpress website. The cheapest and fastest wordpress hosting on the market.</p>

                            <a href={appData.links.wordpressToolkit} className="btn btn-transparent-black">Buy Now</a>

                        </div>

                        {/* <div className="box-item">

                            <i className="cci-server-06"></i>

                            <span>VPS Hosting</span>

                            <p>VPS stands for the virtual private server. You can buy this at {appData.appName}, we provide full SSD-powered virtual servers on a stable 40Gbit network in Miami, buy yours. VPS can be used for multiple purposed.</p>

                            <a href="vps-hosting.html" className="btn btn-transparent-black">See all plans</a>

                        </div> */}

                    </div>

                </div>

            </section>

            <section className="bg-grey">

                <div className="container">

                    <h2 className="h1">With {appData.appName} You Have Many Advantages</h2>

                    <p>See why {appData.appName} is the best choice for your business</p>

                    <div className="box box-info box-four">

                        <div className="box-item" data-aos="fade-up">

                            <i className="cci-support-02"></i>

                            <span>24/7/365 Support</span>

                            <p>We are here to help you with you need when you’re ready to put your website on the Internet.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-blog-02"></i>

                            <span>Our Mission</span>

                            <p>To make affordable hosting solutions available to all . That’s why you can count on us.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-server-06"></i>

                            <span>Hosting Services</span>

                            <p>Our hosting solutions range from hosting websites to dedicated, ultra-fast servers. Find it all here.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-archive-02"></i>

                            <span>All In One Place</span>

                            <p>Create, manage and boost your online presence, from your . com domain to SSL : all in one place.</p>

                        </div>

                    </div>

                </div>

            </section>

            <section>

                {/* <div className="container">

                    <h3 className="h1">Cheaper Domains On The Market</h3>

                    <p>Enter domain name of your choice and pick any extension name on the next step</p>

                    <form className="domain">

                        <input type="text" placeholder="Enter your domain" />

                        <button className="btn btn-blue" type="submit">Check</button>

                    </form>

                    <div className="box domain-ext mt-5 mb-5">

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/com.svg" alt="com domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/net.svg" alt="net domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/online.svg" alt="online domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/shop.svg" alt="shop domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/xyz.svg" alt="xyz domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                        <div className="box-item">

                            <div className="content-img"><img src="/assets/img/design/digital.svg" alt="digital domain" /></div>

                            <cite>$14.00</cite>

                        </div>

                    </div>

                    <div className="box box-info box-four border-unset mt-40">

                        <div className="box-item">

                            <i className="cci-balance-scale-01"></i>

                            <span>Shorter Domain</span>

                            <p>Shortness can help keep a domain name simple and memorable, but going too short can have the opposite effect. Compare “{appData.baseURL}” to “ex.com”</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-best-01"></i>

                            <span>Make It Brandable</span>

                            <p>Your domain name will be your brand. Some names speak for themselves. When you hear the name, you know what the website is about.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-analytics-03"></i>

                            <span>Beware Of Trends</span>

                            <p>Anything that deals with something trendy will, like the trend, fade away. Stick with a classic name that will span the generations for many years.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-blog-02"></i>

                            <span>Be Brandable</span>

                            <p>Your domain name is the face of your company—in the form of a URL. Therefore, you should make sure it actually sounds like a brand.</p>

                        </div>

                    </div>

                </div> */}

            </section>

            <section className="bg-grey">

                <div className="container">

                    <h4 className="h1">Every Feature Your Site Could Ask For</h4>

                    <p>See why {appData.appName} is the best option for your website</p>

                    <div className="box box-info">

                        <div className="box-item">

                            <i className="cci-ssd-01"></i>

                            <span>Unlimited Bandwidth &amp; Storage</span>

                            <p>With Shared Hosting, there’s no limit to the amount of disk space you can use for your site. That means when your site goes viral, you don’t have to stress about storage.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-control-01"></i>

                            <span>Advanced, Easy-to-Use Panel</span>

                            <p>Get admin access to everything needed to power your website full throttle. Easily create new email accounts and Let’s Encrypt SSL, forward domains, add users, and install WordPress in seconds.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-connect-01"></i>

                            <span>Powerful 1-Click Apps Installer</span>

                            <p>If you’re new to web hosting, creating a website from scratch can feel like a time suck. Choose from a variety of pre-assembled apps and let our nifty 1-click installer do the work for you!</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-www-04"></i>

                            <span>1 year FREE domain registration</span>

                            <p>You earn 1 year of free domain registration by choosing the premium hosting package. {appData.appName} pays all costs for you. Don’t miss this opportunity to save.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-email-03"></i>

                            <span>FREE Email Addresses Account</span>

                            <p>You will have unlimited email accounts linked to your registered domain name at no additional cost. Just get any hosting plan shared with us. Get a proffissional email.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-server-secure-02"></i>

                            <span>FREE Privacy Protection In Domain</span>

                            <p>When registering a domain on the internet you will have to share your data with the world. More at {appData.appName}, we deliver whois data protection free of charge.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-ssl-02"></i>

                            <span>CHEAP SSL Certificate Security</span>

                            <p>Get quality SSL certificates for cheap on any shared hosting plan you choose with us. We value security and that’s why we provide this service and many other services related to the security of your site free.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-calendar-01"></i>

                            <span>Automated Backups Daily Free</span>

                            <p>The single most important thing you can do to keep your website safe? Back it up. {appData.appName} makes this easy — we create automatic backups of your WordPress website every day.</p>

                        </div>

                        <div className="box-item">

                            <i className="cci-wordpress-01"></i>

                            <span>Instant WordPress Setup And others apps</span>

                            <p>We can install WordPress for you!* On hosting plans supporting multiple sites, use our effortless 1-click installer to make more WordPress instances in seconds. Installation is always optional.</p>

                        </div>

                    </div>

                </div>

            </section>

            <section aria-label="Question">

                <div className="container">

                    <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                        <div className="content-left m-unset">

                            <div className="box questions">

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is Shared Hosting and why I should choose?</div>

                                    <div className="box-item-body"><p>Shared web hosting service is a web hosting service where many websites reside on one web server connected to the Internet. This is generally the most economical option for hosting, as the overall cost of server maintenance is spread over many customers. By choosing shared hosting, the website will share a physical server with one or more other websites. The service must include system administration since it is shared by many users; this is a benefit for users who do not want to deal with it, but a hindrance to power users who want more control. In general shared hosting will be inappropriate for users who require extensive software development outside what the hosting provider supports. Almost all applications intended to be on a standard web server work fine with a shared web hosting service. But on the other hand, shared hosting is cheaper than other types of hosting such as dedicated server hosting.</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a VPS server and why should I choose?</div>

                                    <div className="box-item-body"><p>A virtual private server (VPS) is a virtual machine sold as a service by an Internet hosting service. The virtual dedicated server (VDS) also works in a virtualized environment but some CPU cores are dedicated for it, which is not the case with VPS. A virtual private server runs its own copy of an operating system (OS), and customers may have superuser-level access to that operating system instance, so they can install almost any software that runs on that OS. For many purposes it is functionally equivalent to a dedicated physical server and, being software-defined, can much more easily be created and configured. A virtual server costs much less than an equivalent physical server. However, as virtual servers share the underlying physical hardware with other VPSes, performance may be lower, depending on the workload of any other executing virtual machines</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a Cloud Computing and why I should choose?</div>

                                    <div className="box-item-body"><p>Cloud computing is the on-demand availability of computer system resources, especially data storage (cloud storage) and computing power, without direct active management by the user. The term is generally used to describe data centers available to many users over the Internet. Large clouds, predominant today, often have functions distributed over multiple locations from central servers. If the connection to the user is relatively close, it may be designated an edge server. Clouds may be limited to a single organization (enterprise clouds), or be available to multiple organizations (public cloud). Cloud computing relies on sharing of resources to achieve coherence and economies of scale.</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a Dedicated Server and why I should choose?</div>

                                    <div className="box-item-body"><p>A dedicated hosting service, dedicated server, or managed hosting service is a type of Internet hosting in which the client leases an entire server not shared with anyone else. This is more flexible than shared hosting, as organizations have full control over the server(s), including choice of operating system, hardware, etc. There is also another level of dedicated or managed hosting commonly referred to as complex managed hosting. Complex Managed Hosting applies to both physical dedicated servers, Hybrid server and virtual servers, with many companies choosing a hybrid (combination of physical and virtual) hosting solution.</p></div>

                                </div>

                            </div>

                        </div>

                        <div className="content-right m-unset">

                            <span className="h2">List of questions</span>

                            <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                            <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                            <div className="btn-group">

                                <a className="btn btn-blue" href="/">Helpdesk</a>
                                <a className="btn btn-grey" href="/">Contact Us</a>

                            </div>

                        </div>

                    </div>

                </div>

               

            </section>

            <section aria-label="Contact Us" className="bg-grey thumb-info">

                <div className="container">

                    <div className="content-left text-left">

                        <span className="h1">If you are in need of help. Contact us immediately.</span>

                    </div>

                    <div className="content-right align-items-center justify-content-center">

                        <a href="/contact-us" className="btn btn-large btn-blue">Contact Us</a>

                    </div>

                </div>

            </section>

            <section>

                {/* <div className="container overflow-hidden">

                    <h5 className="h1">Our customers trust us</h5>

                    <div className="box box-info box-customer box-customer-slider">
                        <OwlCarousel className='owl-theme' {...customerSliderOptions}>
                            {
                                testimonials.map((item, index) => (
                                    <div className="box-item" key={index}>

                                        <div className="content-img"><img src={item.pic.src} alt={item.pic.alt} /></div>

                                        <span>{item.name}</span>

                                        <p>{item.testimony}</p>

                                    </div>
                                ))
                            }
                        </OwlCarousel>
                    </div>
                </div> */}

            </section>

            <section aria-label="About Us" className="bg-grey mb-5">

                <div className="container">

                    <div className="side-by-side">

                        <div className="content-left">

                            <div className="content-img content-img-reverse content-img-borded"><img src="/assets/img/design/company.jpg" alt="IMG Description" /></div>

                        </div>

                        <div className="content-right">

                            <span className="h2">About Us</span>

                            <p>Bringing affordable and reliable webhosting services to all.</p>

                            <div className="btn-group">

                                <a className="btn btn-blue" href={'/'}>About Us</a>
                                <a className="btn btn-grey" href={appData.links.contact}>Contact Us</a>

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <Partners />

        </main>
    )
}

export default Home;