import { Partners } from "../../theme/Sections";

const JoomlaHosting = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Joomla Hosting</span>

                        <span className="h2">Ultra fast Joomla hosting</span>

                        <p>We work focused on improving the infrastructure of our servers and seeking maximum efficiency. Have everything you need to create a powerful Joomla site!</p>
                        
                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">See all plans</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/joomla.svg" alt="Joomla Banner"/></div>

                </div>

            </div>

        </div>

        <section id="plans">

            <div className="container">
                
                <h1 className="h1">Choose A Joomla Hosting Plan</h1>

                <p className="txt-large">Our Joomla hosting plans gives you everything you need to build the perfect site</p>
    
                <div className="box box-info box-price price-style-01 box-three">

                    <div className="box-item scale-hover">

                        <span>Starter Joomla</span>

                        <div className="price-value"><span>$</span><cite>0.96</cite><span>/month</span></div>

                        <p>You pay $1.20 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>10GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>5 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>20MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>200 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Advanced Joomla</span>

                        <div className="price-value"><span>$</span><cite>1.46</cite><span>/month</span></div>

                        <p>You pay $4.98 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>40GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>15 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>40MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>400 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Premium Joomla</span>

                        <div className="price-value"><span>$</span><cite>4.93</cite><span>/month</span></div>

                        <p>You pay $6.17 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>100GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>30 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>40MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>400 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-check-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">WordPress Hosting Features</h2>

                <p>All the WordPress hosting features you need in one convenient place.</p>

                <div className="box box-info justify-center mt-25">

                    <div className="box-item">

                        <i className="cci-support-05"></i>
                        
                        <span>Expert Support in Joomla</span>

                        <p>Need help setting up or migrating your website? We help you! A dedicated team of trained professionals will be available to solve your questions 24/7.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-spped-01"></i>
                        
                        <span>Optimized Joomla servers</span>

                        <p>Choose a high level of performance and speed in the loading time of your website. All our servers are optimized to ensure the best Joomla hosting!</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-www-01"></i>
                        
                        <span>Free domain name</span>

                        <p>At FineHost, you can get the best domain and still register it for free! All plans starting at 12 months include a free domain registration for the first year.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-usability-01"></i>
                        
                        <span>Simple and Flexible</span>

                        <p>Is it your first website? The simplicity of Joomla will surprise you. Set everything up in one click and create a professional website even without technical knowledge!</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-target-02"></i>
                        
                        <span>Solid Comunity</span>

                        <p>Each year Joomla attracts a wide community of users. Joomla is complete with everything a website needs and the solutions to any challenge that comes up!</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-setting-05"></i>
                        
                        <span>Themes and Extensions</span>

                        <p>With a huge library of free themes and plugins, Joomla offers incredible freedom of creation. Add new features in the blink of an eye and impress your visitors with gorgeous designs!</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is hosting?</div>

                                <div className="box-item-body"><p>Let's break it down. The basics: you want a website. You've got great ideas for content, a vision board describing your site's mood, and even a wishlist for additional functions you want for your website. But how exactly do you get your site online? That's where hosting comes in. Your website needs a hosting provider to get it up on the web and publicly available to visit. Web hosts are companies that provide the server space necessary to store your website. As web traffic attempts to access your site, the necessary data is transmitted from the server to the visitors' device. Web hosting providers do, however, differ in the variety of plan types and options they offer. So having a quality web host is crucial.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is it, Joomla?</div>

                                <div className="box-item-body"><p>Currently one of the most popular content management systems (CMS) in the world, Joomla! is a flexible platform that you can use to create any type of website. Since its launch in 2005, it offers the opportunity to create an online presence for millions of users, bloggers and business owners around the world.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What’s different about Joomla website hosting?</div>

                                <div className="box-item-body"><p>Packed with performance upgrades and custom configuration, Joomla website hosting offers the perfect setup for sites created in Joomla. Focus on the quality and content of your web pages as we take care of uptime, server updates and enhancements. Make the most of CMS Joomla with Hostinger.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">How to migrate my existing Joomla site?</div>

                                <div className="box-item-body"><p>If you already have a website in Joomla and want to make the transfer, first you must make a complete backup. The easiest way to do this is by using a plugin like Akeeba Backup, which allows you to backup and restore websites in Joomla with ease. If you have problems, our customer success team is ready to help you day and night!</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners/>
    </main>);
}

export default JoomlaHosting;