import { appData } from "../../app_data";

// const links = {
//     link1: appData.links.sharedHosting,
//     text1: 'See all plans',
//     link2: appData.links.contact,
//     text2: 'Contact Us',
// };

export const topData = [
    {
        title: 'Shared Hosting',
        subtitle: 'High Quality Shared Hosting With Free Domain Registration.',
        content: 'Core shared hosting for small business and personal websites. Advanced and Advanced Plus hosting for e-commerce and more robust business web hosting needs. Expect nothing less than optimal server stability.',
        link1: appData.links.sharedHosting,
        text1: 'See all plans',
        link2: appData.links.contact,
        text2: 'Contact Us',
        className: 'slider-item slider-active',
        img:
            { src: '/assets/img/design/server-13.svg', alt: 'Server Banner' }
        ,
    },
    {
        title: 'Wordpress Hosting',
        subtitle: '24/7 Wordpress Management, Support and Unlimited Hosting',
        content: `Manage all your WordPress sites and server from one place. Enjoy features like free daily backups to ${appData.appName}, Daily site analytics, Live server usage stats and more. A perfect White Label WordPress Hosting solution for Web agencies and WordPress developers.`,
        link1: appData.links.sharedHosting,
        text1: 'See all plans',
        link2: appData.links.contact,
        text2: 'Contact Us',
        className: 'slider-item',
        img:
            { src: '/assets/img/design/wordpress.svg', alt: 'Wordpress Banner' }
        ,
    },
    {
        title: 'Joomla Hosting',
        subtitle: `Why Get Joomla Hosting From ${appData.appName}`,
        content: `${appData.appName} Joomla! on our secure VMware platform. Using HP3PAR and Cisco technologies, our enterprise-grade cloud infrastructure has a no single point of failure platform design. ${appData.appName} offers a 20-minute hardware replacement SLA and our highly skilled team of engineers are here to support you 24/7.`,
        link1: appData.links.sharedHosting,
        text1: 'See all plans',
        link2: appData.links.contact,
        text2: 'Contact Us',
        className: 'slider-item',
        img:
            { src: '/assets/img/design/joomla.svg', alt: 'Joomla Banner' }
        ,
    },
    {
        title: 'VPS Hosting',
        subtitle: 'High Quality And Powerful VPS Servers With Os Linux Or Windows',
        content: 'We offer both Windows and Linux VPS hosting with pure SSD drives, making sure a high-level performance and convenience. With enterprise-class data centers and infrastructure, we provide a 99.9% uptime guarantee for our servers and network.',
        link1: appData.links.sharedHosting,
        text1: 'See all plans',
        link2: appData.links.contact,
        text2: 'Contact Us',
        className: 'slider-item',
        img:
            { src: '/assets/img/design/server-03.svg', alt: 'Server Banner' }
        ,
    },
];

export const testimonials = [
    {
        name: 'Funtaj International School',
        pic: { src: 'https://funtajschoolltd.com/main/images/logo.png', alt: 'Funtaj International School' },
        testimony: '',
    },
    {
        name: 'Kings Care Universal School',
        pic: { src: 'https://kingscareschool.com/assets/img/apple-touch-icon.png', alt: 'Kings Care Universal School' },
        testimony: '',
    },
];