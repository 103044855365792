export const ContactMenu = () => {
    return (<div className="content-right">

        {/* <span className="h3">Contact Us</span>

        <p>We are here to help you, either to reset your password or to create your entire online experience.</p>

        <a href="/" className="btn btn-blue">Contact Us</a> */}

    </div>);
}