import { Partners } from "../../theme/Sections";

const SSL = () => {
    return (
        <main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">SSL Certificate</span>

                        <span className="h2">Show your visitors that you are trustworthy</span>

                        <p>Every organization should buy an SSL certificate to establish a secure environment and prove their business trustworthiness. Get hassle-free security solutions with our low-cost SSL certificates.</p>
                        
                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">See all plans</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/private-07.svg" alt="Support Banner"/></div>

                </div>

            </div>

        </div>

        <section id="plans">

            <div className="container">
                
                <h1 className="h1">Choose Your SSL Certificate Plan</h1>

                <p className="txt-large">Show visitors that you are trustworthy and authentic.</p>
    
                <div className="box box-info box-price price-style-01 box-three">

                    <div className="box-item scale-hover">

                        <span>Domain Validated SSL</span>

                        <div className="price-value"><span>$</span><cite>12</cite><span>/month</span></div>

                        <p>Basic protection for your website and help with Google rankings.</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>$10,000 warranty</span></li>
                            <li><i className="cci-check-03"></i> <span>Secure customer information</span></li>
                            <li><i className="cci-check-03"></i> <span>May help boost Google rankings</span></li>
                            <li><i className="cci-check-03"></i> <span>TrustLogo® Site Seal</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Protect multiple subdomains</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Enhanced e-commerce conversion</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Green "trust" visual bar</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Wildcard SSL</span>

                        <div className="price-value"><span>$</span><cite>14</cite><span>/month</span></div>

                        <p>Secure your main website and various subdomains linked to your main domain.</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>$250,000 warranty</span></li>
                            <li><i className="cci-check-03"></i> <span>Secure customer information</span></li>
                            <li><i className="cci-check-03"></i> <span>May help boost Google rankings</span></li>
                            <li><i className="cci-check-03"></i> <span>TrustLogo® Site Seal</span></li>
                            <li><i className="cci-check-03"></i> <span>Protect multiple subdomains</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Enhanced e-commerce conversion</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Green "trust" visual bar</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>E-Commerce SSL</span>

                        <div className="price-value"><span>$</span><cite>17</cite><span>/month</span></div>

                        <p>Protect your e-commerce and payments with the most advanced protection.</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>$1,750,000 warranty</span></li>
                            <li><i className="cci-check-03"></i> <span>Secure customer information</span></li>
                            <li><i className="cci-check-03"></i> <span>May help boost Google rankings</span></li>
                            <li><i className="cci-check-03"></i> <span>TrustLogo® Site Seal</span></li>
                            <li><i className="cci-check-03"></i> <span>Protect multiple subdomains</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Enhanced e-commerce conversion</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Green "trust" visual bar</span></li>

                        </ul>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="SSL Certificate Features" className="bg-grey">

            <div className="container">

                <div className="side-by-side">

                    <div className="content-left">

                        <div className="content-img"><img src="/assets/img/design/private-05.svg" alt="Private"/></div>

                    </div>

                    <div className="content-right content-text">
    
                        <span>See why get an SSL certificate</span>
    
                        <span>Why get an SSL certificate?</span>

                        <p>An SSL certificate reduces your risk by keeping sensitive data collected on your website safe. The data is encrypted and backed by a warranty from Comodo SSL that's worth up to $1.75M to protect the end user. Having HTTPS in the address bar and displaying a seal of trust increases customer confidence in your website and helps drive more sales.</p>
    
                    </div>

                </div>

                <div className="side-by-side side-by-side-reverse">

                    <div className="content-left">

                        <div className="content-img"><img src="/assets/img/design/ads-01.svg" alt="Private"/></div>

                    </div>

                    <div className="content-right content-text">
    
                        <span>Boost Google rankings with ssl</span>
    
                        <span>Boost Google Rankings</span>

                        <p>Good news. Google says that SSL security has a positive effect on its rankings: "We're starting to use HTTPS as a ranking signal... for sites (that) use secure, encrypted connections (and) we've seen positive results." - Google Online Security Blog, August 06, 2014.</p>
    
                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is SSL/TLS certificate?</div>

                                <div className="box-item-body"><p>Before proceeding to the point on why the site needs an SSL certificate, it is worth noting the concept of the SSL protocol itself. This is a cryptographic protocol that provides reliable data transmission over the network. It is a guarantee of a secure connection between the user browser and the resource.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is the difference between HTTP and HTTPS?</div>

                                <div className="box-item-body"><p>HTTPS has greatly enhanced the security of HTTP data. If SSL is installed on the site, then all data is transmitted via HTTPS - a secure version of the HTTP protocol. It encrypts user data and forwards it to the site owner via the TCP transport protocol. In other words, all information transmitted by the user is hidden by encryption from third parties: operators, Wi-Fi administrators, and providers.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">How SSL protocol works?</div>

                                <div className="box-item-body">

                                    <span className="txt-medium">How SSL protocol works As you know, the basis of all encoding methods is a key that helps to encrypt or read information. The SSL protocol uses an asymmetric cipher with two kinds of keys:</span>

                                    <ul>
                                        <li>Public. This is actually an SSL certificate. It encrypts data and is used when transmitting user information to the server. For example, a visitor enters his bank card number on the site and clicks on the "Pay" button.</li>
                                        <li>Private. Required to decode the message on the server. It is not transmitted along with the information, as is the case with the public key, and always remains on the server.</li>
                                        <li>For a site to handle such connections, its owner needs an SSL certificate. This is a kind of digital signature, which is individual for each platform.</li>
                                    </ul>

                                </div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What's inside an SSL certificate?</div>

                                <div className="box-item-body">

                                    <span>An SSL certificate may contain the following important information:</span>

                                    <ul>
                                        <li>Domain of the site on which the certificate is installed;</li>
                                        <li>Name of the company owner;</li>
                                        <li>Country, city of company registration;</li>
                                        <li>SSL certificate validity period;</li>
                                        <li>Certification Authority Information;</li>
                                        <li>Serial number of the SSL;</li>
                                        <li>SAN items (Multi-domain SSL);</li>
                                        <li>Trusted and Untrusted Certificates.</li>
                                    </ul>
        
                                    <span>The main source of SSL certificates are trusted certification authorities or Certification authorities (CAs). These are organizations that have undeniable authority in the IT services market and use the well-known public cryptographic key. In browsers, their list can usually be found in the "Trusted Root Certification Authorities" section.</span>
        
                                    <p>A digital signature certified by a certificate of such a center is a proof of the authenticity of the company that owns the domain name and determines the right of the owner to legally use the secret key. It is called trusted.</p>

                                </div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>
    );
}

export default SSL;