import { Partners } from "../../theme/Sections";

const PrivateHosting = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Private Hosting</span>

                        <span className="h2">Your only server managed by us</span>

                        <p>Private hosting is really nothing more than a unique VPS server for you, managed by us. That is, we manage your server if you don’t know it. You only care about your website.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">See all plans</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/private-06.svg" alt="Private Banner" /></div>

                </div>

            </div>

        </div>

        <section id="plans">

            <div className="container">

                <h1 className="h1">Choose A Private Hosting Plan</h1>

                <p className="txt-large">Server totally managed by us. You only care about your website.</p>

                <div className="box box-info box-price price-style-01 box-three">

                    <div className="box-item scale-hover">

                        <span>Starter Hosting</span>

                        <div className="price-value"><span>$</span><cite>1.66</cite><span>/month</span></div>

                        <p>You pay $4.17 on next renewal</p>

                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>20GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>1GB Ram</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>App Installer/Manager</span></li>
                            <li><i className="cci-check-03"></i> <span>Drag &amp; Drop Website Builder</span></li>
                            <li><i className="cci-check-03"></i> <span>Free SSL Certificate</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>SSH Access</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>
                            <li><i className="cci-check-03"></i> <span>SpamExperts Outgoing Filter</span></li>
                            <li><i className="cci-check-03"></i> <span>BW Oversell Allowed</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Features Allowed</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Advanced Hosting</span>

                        <div className="price-value"><span>$</span><cite>2.85</cite><span>/month</span></div>

                        <p>You pay $7.17 on next renewal</p>

                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>50GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>2GB Ram</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>App Installer/Manager</span></li>
                            <li><i className="cci-check-03"></i> <span>Drag &amp; Drop Website Builder</span></li>
                            <li><i className="cci-check-03"></i> <span>Free SSL Certificate</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>SSH Access</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>
                            <li><i className="cci-check-03"></i> <span>SpamExperts Outgoing Filter</span></li>
                            <li><i className="cci-check-03"></i> <span>BW Oversell Allowed</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Features Allowed</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Premium Hosting</span>

                        <div className="price-value"><span>$</span><cite>5.67</cite><span>/month</span></div>

                        <p>You pay $14.18 on next renewal</p>

                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>300GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>4GB Ram</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>App Installer/Manager</span></li>
                            <li><i className="cci-check-03"></i> <span>Drag &amp; Drop Website Builder</span></li>
                            <li><i className="cci-check-03"></i> <span>Free SSL Certificate</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>SSH Access</span></li>
                            <li><i className="cci-check-03"></i> <span>Domain 1 year free</span></li>
                            <li><i className="cci-check-03"></i> <span>SpamExperts Outgoing Filter</span></li>
                            <li><i className="cci-check-03"></i> <span>BW Oversell Allowed</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited Features Allowed</span></li>

                        </ul>

                    </div>

                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">Every Feature Your Site Could Ask For</h2>

                <p>See why FineHost is the best option for your website</p>

                <div className="box box-info justify-center">

                    <div className="box-item">

                        <i className="cci-ssd-01"></i>

                        <span>Unlimited Bandwidth &amp; Storage</span>

                        <p>With Private Hosting, there’s no limit to the amount of disk space you can use for your site. That means when your site goes viral, you don’t have to stress about storage.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-control-01"></i>

                        <span>Advanced, Easy-to-Use Panel</span>

                        <p>Get admin access to everything needed to power your website full throttle. Easily create new email accounts and Let’s Encrypt SSL, forward domains, add users, and install WordPress in seconds.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-connect-01"></i>

                        <span>Powerful 1-Click Apps Installer</span>

                        <p>If you’re new to web hosting, creating a website from scratch can feel like a time suck. Choose from a variety of pre-assembled apps and let our nifty 1-click installer do the work for you!</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-www-04"></i>

                        <span>1 year FREE domain registration</span>

                        <p>You earn 1 year of free domain registration by choosing the premium hosting package. FineHost pays all costs for you. Don’t miss this opportunity to save.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-email-03"></i>

                        <span>FREE Email Addresses Account</span>

                        <p>You will have unlimited email accounts linked to your registered domain name at no additional cost. Just get any hosting plan private with us. Get a proffissional email.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-server-secure-02"></i>

                        <span>FREE Privacy Protection In Domain</span>

                        <p>When registering a domain on the internet you will have to share your data with the world. More at FineHost, we deliver whois data protection free of charge.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-ssl-02"></i>

                        <span>FREE SSL Certificate Security</span>

                        <p>Get SSL certificate for free on any private hosting plan you choose with us. We value security and that’s why we provide this service and many other services related to the security of your site free.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-calendar-01"></i>

                        <span>Automated Backups Daily Free</span>

                        <p>The single most important thing you can do to keep your website safe? Back it up. FineHost makes this easy — we create automatic backups of your WordPress website every day.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-wordpress-01"></i>

                        <span>Instant WordPress Setup And others apps</span>

                        <p>We can install WordPress for you!* On hosting plans supporting multiple sites, use our effortless 1-click installer to make more WordPress instances in seconds. Installation is always optional.</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Hosting Features">

            <div className="container">

                <div className="side-by-side">

                    <div className="content-left">

                        <div className="content-img reverse"><img src="/assets/img/design/server-09.svg" alt="Server" /></div>

                    </div>

                    <div className="content-right content-text">

                        <span className="t-medium txt-black">We have Fast Solid State Storage</span>

                        <span className="title t-extra-large txt-black">Fast Solid State Storage</span>

                        <p className="txt-large txt-black">Don't make your visitors wait around for lengthy load times. With SSDs, your website, caching, and database queries are faster — we're talking 200 percent faster — than old school HDDs. That means, your visitors get to enjoy your website without being bogged down by lagging load times. No taking a number here!</p>

                    </div>

                </div>

                <div className="side-by-side side-by-side-reverse">

                    <div className="content-left">

                        <div className="content-img reverse"><img src="/assets/img/design/server-08.svg" alt="Server" /></div>

                    </div>

                    <div className="content-right content-text">

                        <span className="t-medium txt-black">All Optimized Servers</span>

                        <span className="title t-extra-large txt-black">Optimized Servers</span>

                        <p className="txt-large txt-black">We get it. You need a website that performs at full capacity. Because we're basically WordPress' No. 1 fan, we’ve designed all of our services with optimum WordPress performance in mind. From our servers to database software, all our tech is built to run WordPress like a dream, meaning you get the best configurations for your website. Plus, automatic updates and one-click installs makes integration seamless.</p>

                    </div>

                </div>

                <div className="side-by-side">

                    <div className="content-left">

                        <div className="content-img reverse"><img src="/assets/img/design/contract-02.svg" alt="Contract" /></div>

                    </div>

                    <div className="content-right content-text">

                        <span className="t-medium txt-black">100% Uptime Guarantee for you</span>

                        <span className="title t-extra-large txt-black">100% Uptime Guarantee</span>

                        <p className="txt-large txt-black">Thoughts of downtime got you down? We understand. Our high-tech systems (think multiple datacenter locations, redundant cooling, emergency generators, and constant monitoring) are specially designed to keep your website fully-functional around the clock. We’re so confident in our tech, that our 100% Uptime Guarantee will put $$$ back in your pocket if your site goes down.</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Contact Us" className="bg-grey thumb-info">

            <div className="container">

                <div className="content-left text-left">

                    <span className="h1">See you are in need of help. Contact us immediately.</span>

                </div>

                <div className="content-right align-items-center justify-content-center">

                    <a href="contact-us.html" className="btn btn-large btn-blue">Contact Us</a>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What is hosting?</div>

                                <div className="box-item-body"><p>Let's break it down. The basics: you want a website. You've got great ideas for content, a vision board describing your site's mood, and even a wishlist for additional functions you want for your website. But how exactly do you get your site online? That's where hosting comes in. Your website needs a hosting provider to get it up on the web and publicly available to visit. Web hosts are companies that provide the server space necessary to store your website. As web traffic attempts to access your site, the necessary data is transmitted from the server to the visitors' device. Web hosting providers do, however, differ in the variety of plan types and options they offer. So having a quality web host is crucial.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">Whats is private hosting?</div>

                                <div className="box-item-body"><p>Private hosting as well-informed name and a private environment for your website. Resources are not shared with other users. And you have complete freedom to manage the server.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">How many websites can I host?</div>

                                <div className="box-item-body"><p>Just getting started? With a Shared Starter plan, you can host one website — a great place to begin!  If you're looking to manage a handful of sites, a Shared Unlimited plan allows you to host any number of websites (we say unlimited and we mean it!). Whatever flexibility you need, FineHost offers it.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">Do you include SSL certificates in private hosting?</div>

                                <div className="box-item-body"><p>For free to add to your site. In this day and age, having an SSL for your site is a no-brainer best practice. Not only does an SSL help your visitors feel safe interacting with your site — this is particularly important if you run an e-commerce site — but it avoids penalizations you might otherwise earn from search engines without one. No website wants an “unsafe” or “insecure” label to bruise their reputation. While a SSL certificate isn't an end-all, be-all defense against internet baddies, it's certainly a great first step in the right direction to putting your site visitors at ease.</p></div>

                            </div>

                        </div>

                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">

                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>);
}

export default PrivateHosting;