import { Partners } from "../../theme/Sections";

const WebsiteBuilder = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Website Builder</span>

                        <span className="h2">Easy Website Builder</span>

                        <p>8b offers the latest themes and templates for you to design and generate your own unique websites as you wish. Click on a template you like to start creating your website!</p>
                        
                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">See all plans</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-medium"><img src="/assets/img/design/website-builder-02.svg" alt="Website Builder Banner"/></div>

                </div>

            </div>

        </div>

        <section>

            <div className="container">

                <h1 className="h1">Make Yourself Your Business Or Personal Website</h1>

                <p>Choose how you want to create your website according to your business needs</p>

                <div className="box box-info box-transparent border-unset justify-center">

                    <div className="box-item">

                        <i className="cci-typography-01"></i>
                        
                        <span>Easy to use</span>

                        <p>With the touch and edit system it’s very easy to add and edit elements, create an amazing website.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-online-shop-01"></i>
                        
                        <span>Mobile version</span>

                        <p>Create a professional website prepared to adapt to any device, no more losing access by not having a responsive site</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-security-01"></i>
                        
                        <span>Security</span>

                        <p>Security is a serious thing. All models have a free SSL certificate, ensuring more security for you</p>

                    </div>

                </div>

            </div>

        </section>

        <section id="plans" className="bg-grey">

            <div className="container">
                
                <h2 className="h1">Choose Your Website Builder Plan Below!</h2>

                <p className="txt-large">Turn your ideas into reality with our website builder.</p>
    
                <div className="box box-info box-price price-style-01 box-three">

                    <div className="box-item scale-hover">

                        <span>Website Builder 1</span>

                        <div className="price-value"><span>$</span><cite>4</cite><span>/month</span></div>
                        
                        <p>Ideal for small websites</p>

                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-01"></i><span>4GB Bandwidth</span></li>
                            <li><i className="cci-check-01"></i><span>3 GB Storage</span></li>
                            <li><i className="cci-check-01"></i><span>Connect your domain</span></li>
                            <li><i className="cci-check-01"></i><span>Designer crafted templates</span></li>
                            <li><i className="cci-check-01"></i><span>SSL Security</span></li>
                            <li><i className="cci-check-01"></i><span>Get found with SEO</span></li>
                            <li><i className="cci-uncheck-01"></i><span>AI Tools</span></li>
                            <li><i className="cci-uncheck-01"></i><span>Free domain for 1 year</span></li>
                            <li><i className="cci-uncheck-01"></i><span>Powerful integrations</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Website Builder 2</span>

                        <div className="price-value"><span>$</span><cite>14</cite><span>/month</span></div>

                        <p>Ideal for medium websites</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-01"></i><span>30GB Bandwidth</span></li>
                            <li><i className="cci-check-01"></i><span>40 GB Storage</span></li>
                            <li><i className="cci-check-01"></i><span>Connect your domain</span></li>
                            <li><i className="cci-check-01"></i><span>Designer crafted templates</span></li>
                            <li><i className="cci-check-01"></i><span>SSL Security</span></li>
                            <li><i className="cci-check-01"></i><span>Get found with SEO</span></li>
                            <li><i className="cci-check-01"></i><span>AI Tools</span></li>
                            <li><i className="cci-uncheck-01"></i><span>Free domain for 1 year</span></li>
                            <li><i className="cci-check-01"></i><span>Powerful integrations</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Website Builder 3</span>

                        <div className="price-value"><span>$</span><cite>22</cite><span>/month</span></div>

                        <p>Ideal for large websites</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-01"></i><span>500GB Bandwidth</span></li>
                            <li><i className="cci-check-01"></i><span>100 GB Storage</span></li>
                            <li><i className="cci-check-01"></i><span>Connect your domain</span></li>
                            <li><i className="cci-check-01"></i><span>Designer crafted templates</span></li>
                            <li><i className="cci-check-01"></i><span>SSL Security</span></li>
                            <li><i className="cci-check-01"></i><span>Get found with SEO</span></li>
                            <li><i className="cci-check-01"></i><span>AI Tools</span></li>
                            <li><i className="cci-check-01"></i><span>Free domain for 1 year</span></li>
                            <li><i className="cci-check-01"></i><span>Powerful integrations</span></li>

                        </ul>

                    </div>

                </div>

            </div>

        </section>

        <section>

            <div className="container">

                <h3 className="h1">Our Builder Website Is Modern And Efficient</h3>

                <p>If you want to create a unique website without knowing programming. Our builder is perfect for you</p>

                <div className="box box-info justify-center">

                    <div className="box-item">

                        <i className="cci-file-03"></i>
                        
                        <span>Free Image Bank</span>

                        <p>Hundreds of pictures, just choose If a picture is worth a thousand words, how about hundreds of them? Make your website much more attractive with totally free professional images.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-design-02"></i>
                        
                        <span>Customize As You Wish</span>

                        <p>Edit your website however you want! Add new pages, menus, images, buttons, texts without the need to have programming knowledge. Our editor is easy and intuitive.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-coding-01"></i>
                        
                        <span>Custom Page</span>

                        <p>From menu to event planner, you can go beyond and customize your website pages however you want! Customize buttons, borders, icons, menus, banners and more.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-responsive-01"></i>
                        
                        <span>Responsive design</span>

                        <p>No matter which device your visitors are using, all models have responsive design. Responsive design means your site will always be displayed seamlessly from the all resolutions.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-analytics-01"></i>
                        
                        <span>Website statistics</span>

                        <p>Track the number of visitors in real time. On which pages users of your website meet and other statistics with the analysis tool. Use the data to further improve your website.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-seo-01"></i>
                        
                        <span>Best SEO</span>

                        <p>Our templates have the best seo practice. Turn Google searches into visits to your site! Include the right keywords on your pages and increase your chances of being found on the internet.</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Contact Us" className="bg-grey thumb-info">

            <div className="container">

                <div className="content-left text-left">

                    <span className="h1">See you are in need of help. Contact us immediately.</span>
                    
                </div>

                <div className="content-right align-items-center justify-content-center">

                    <a href="contact-us.html" className="btn btn-large btn-blue">Contact Us</a>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">Why should I have a website?</div>

                                <div className="box-item-body"><p>By creating a website, you’re creating an online presence. This allows you to connect with people you couldn’t reach otherwise. If you are creating a basic website with contact information for your small business or medical clinic, a presentation page for your freelance work, a multi-page experience for your wedding photography company or just a blog to talk about your food reviews, creating a website will give you that dynamic edge.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is FineHost Website Builder?</div>

                                <div className="box-item-body"><p>FineHost Website Creator is an online editing and publishing tool that helps you create a website, regardless of your level of technical knowledge. Simply create an account, choose a design, and use the built-in editor to add your own images and text. Your site can be simple or complex. You decide. Need to sell your products online? No problem. You can add a complete online store with product lists, coupons, shopping cart, shipping and various payment options.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">Can I cancel anytime?</div>

                                <div className="box-item-body"><p>Yes. If you decide that FineHost is not what your business needs, you can cancel at any time by email.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">Is there a discount for annual service subscriptions?</div>

                                <div className="box-item-body"><p>Yes. When you purchase an early annual package, you get a discount, a free domain . and a year of private domain registration.</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>);
}

export default WebsiteBuilder;