import { appData } from "../../app_data";
import { Partners } from "../../theme/Sections";

const RegisterDomain = () => {
    return (
        <main>

        <section>

            <div className="container">

                <h1 className="h1">Cheaper Domains On The Market</h1>

                <p>Enter domain name of your choice and pick any extension name on the next step</p>

                <form id="domain-register" className="domain">

                    <input type="text" placeholder="Enter your domain"/>

                    <button className="btn btn-blue" type="submit">Check</button>

                </form>

                <div className="box domain-ext mt-5 mb-5">

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/com.svg" alt="com domain"/></div>

                        <cite>$14.00</cite>

                    </div>

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/net.svg" alt="net domain"/></div>

                        <cite>$14.00</cite>

                    </div>

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/online.svg" alt="online domain"/></div>

                        <cite>$14.00</cite>

                    </div>

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/shop.svg" alt="shop domain"/></div>

                        <cite>$14.00</cite>

                    </div>

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/xyz.svg" alt="xyz domain"/></div>

                        <cite>$14.00</cite>

                    </div>

                    <div className="box-item">

                        <div className="content-img"><img src="/assets/img/design/digital.svg" alt="digital domain"/></div>

                        <cite>$14.00</cite>

                    </div>
    
                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">How to choose the best domain</h2>

                <p>Below we list some suggestions for choosing a best domain for registration</p>

                <div className="box box-info box-four border-unset">
    
                    <div className="box-item">

                        <i className="cci-balance-scale-01"></i>

                        <span>Shorter Domain</span>

                        <p>Shortness can help keep a domain name simple and memorable, but going too short can have the opposite effect. Compare “example.com” to “ex.com”</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-best-01"></i>

                        <span>Make It Brandable</span>

                        <p>Your domain name will be your brand. Some names speak for themselves. When you hear the name, you know what the website is about.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-analytics-03"></i>

                        <span>Beware Of Trends</span>

                        <p>Anything that deals with something trendy will, like the trend, fade away. Stick with a classic name that will span the generations for many years.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-blog-02"></i>

                        <span>Be Brandable</span>

                        <p>Your domain name is the face of your company—in the form of a URL. Therefore, you should make sure it actually sounds like a brand.</p>

                    </div>
    
                </div>

            </div>

        </section>

        <section>

            <div className="container">

                <h3 className="h1">Below we list domains extension we support</h3>

                <p>The most popular top domain names that fit you best</p>

                <div id="domain-extensions" className="box box-info box-img justify-center">

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/com.svg" alt=".com domain icon"/></div>

                        <p className="text-left">This is the most popular domain you will find. Great choice for your business.</p>

                        <del className="text-left">$8.25</del>

                        <div className="price-value text-left"><span>$</span><cite>7.88</cite><span>/year</span></div>

                        <button data-extension=".com" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/net.svg" alt=".net domain icon"/></div>

                        <p className="text-left">Show your customers that you have a site with the .net extension.</p>

                        <del className="text-left">$7.25</del>

                        <div className="price-value text-left"><span>$</span><cite>6.18</cite><span>/year</span></div>

                        <button data-extension=".net" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/online.svg" alt=".online domain icon"/></div>

                        <p className="text-left">It’s a great alternative to .com. Broad, generic and universal.</p>

                        <del className="text-left">$7.25</del>

                        <div className="price-value text-left"><span>$</span><cite>6.18</cite><span>/year</span></div>

                        <button data-extension=".online" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/live.svg" alt=".live domain icon"/></div>

                        <p className="text-left">Domain extension Perfect for Fresh and real-time content.</p>

                        <del className="text-left">$3.14</del>

                        <div className="price-value text-left"><span>$</span><cite>2.28</cite><span>/year</span></div>

                        <button data-extension=".live" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/link.svg" alt=".link domain icon"/></div>

                        <p className="text-left">A way to connect with your audience and drive call-to-actions.</p>

                        <del className="text-left">$1.38</del>

                        <div className="price-value text-left"><span>$</span><cite>2.46</cite><span>/year</span></div>

                        <button data-extension=".link" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/xyz.svg" alt=".xyz domain icon"/></div>

                        <p className="text-left">Unique and trending domain for your successful business.</p>

                        <del className="text-left">$2.52</del>

                        <div className="price-value text-left"><span>$</span><cite>1.34</cite><span>/year</span></div>

                        <button data-extension=".xyz" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/digital.svg" alt=".digital domain icon"/></div>

                        <p className="text-left">Show your customers that you do business with .digital extension.</p>

                        <del className="text-left">$6.12</del>

                        <div className="price-value text-left"><span>$</span><cite>4.38</cite><span>/year</span></div>

                        <button data-extension=".digital" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/me.svg" alt=".me domain icon"/></div>

                        <p className="text-left">Personalize your web presence with the .me domain extension</p>

                        <del className="text-left">$7.12</del>

                        <div className="price-value text-left"><span>$</span><cite>3.38</cite><span>/year</span></div>

                        <button data-extension=".me" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                    <div className="box-item">

                        <div className="content-img m-unset"><img src="/assets/img/design/shop.svg" alt=".shop domain icon"/></div>

                        <p className="text-left">Perfect domain name for stores that want to show online presence.</p>

                        <del className="text-left">$9.24</del>

                        <div className="price-value text-left"><span>$</span><cite>6.53</cite><span>/year</span></div>

                        <button data-extension=".shop" className="btn btn-big btn-blue-transparent mt-25 w-100">Register</button>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Transfer Domain" className="bg-grey thumb-info">

            <div className="container">

                <div className="content-left text-left">

                    <span className="h1">See you want to acquire the benefits of {appData.appName} and already have a domain. Transfer to us now.</span>
                    
                </div>

                <div className="content-right align-items-center justify-content-center">

                    <a href="domain-transfer.html" className="btn btn-large btn-blue">Transfer Domain</a>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is domain name?</div>

                                <div className="box-item-body"><p>A domain name is an identification string that defines a realm of administrative autonomy, authority or control within the Internet. Domain names are used in various networking contexts and for application-specific naming and addressing purposes. In general, a domain name identifies a network domain, or it represents an Internet Protocol (IP) resource, such as a personal computer used to access the Internet, a server computer hosting a website, or the web site itself or any other service communicated via the Internet. In 2017, 330.6 million domain names had been registered.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is purpose of domain name?</div>

                                <div className="box-item-body"><p>Domain names serve to identify Internet resources, such as computers, networks, and services, with a text-based label that is easier to memorize than the numerical addresses used in the Internet protocols. A domain name may represent entire collections of such resources or individual instances. Individual Internet host computers use domain names as host identifiers, also called hostnames. The term hostname is also used for the leaf labels in the domain name system, usually without further subordinate domain name space. Hostnames appear as a component in Uniform Resource Locators (URLs) for Internet resources such as websites (e.g., en.example.com).</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is history of domain name?</div>

                                <div className="box-item-body"><p>The practice of using a simple memorable abstraction of a host's numerical address on a computer network dates back to the ARPANET era, before the advent of today's commercial Internet. In the early network, each computer on the network retrieved the hosts file (host.txt) from a computer at SRI (now SRI International),[5][6] which mapped computer hostnames to numerical addresses. The rapid growth of the network made it impossible to maintain a centrally organized hostname registry and in 1983 the Domain Name System was introduced on the ARPANET and published by the Internet Engineering Task Force as RFC 882 and RFC 883.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is top-level domains?</div>

                                <div className="box-item-body"><p>TLD, or Top Level Domain, is a part of a website address that comes just after the last point of the URL. For better understanding, the TLD would be the exact location of a company, its full address. Some examples of TLD are . info, . com, . us, . co among others. Obviously, it is not on a random domain, its goal is to carry out a sort of ranking of the site. That is, a commercial site whose top-level Domain is . com is reporting that it is a commercial site. As an example we have Wordpress.com, which is a commercial site, while Wordpress.org is a non-profit version of the group.</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />
       
    </main>
    );
}

export default RegisterDomain;