import { Partners } from "../../theme/Sections";

const ShopBuilder = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Easy Shop Builder</span>

                        <span className="h2">Set up your online store in minutes</span>

                        <p>Have an online store, increase your sales and manage everything in one place. Build the best shopping experience. Make your first sale faster with tools that attract new customers.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/">Get Started</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/shop-builder-01.svg" alt="Shop Builder Banner" /></div>

                </div>

            </div>

        </div>

        <section>

            <div className="container">

                <h1 className="h1">Reward And Engage Your Customers</h1>

                <p>Reach customers more easily with our store builder</p>

                <div className="box box-info box-transparent border-unset justify-center">

                    <div className="box-item">

                        <i className="cci-discount-01"></i>

                        <span>Discounts</span>

                        <p>Move older stock easily by offering discounts for a quick sale.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-promotion-01"></i>

                        <span>Coupons</span>

                        <p>Personalize the shopping experience by extending coupons to loyal customers.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-ads-01"></i>

                        <span>Google Ads</span>

                        <p>Automated marketing tools help you move your products with tailored ads.</p>

                    </div>

                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">Lighten Your Workload And Increase Visibility</h2>

                <p>Build an eCommerce website that's got it all</p>

                <div className="box box-info justify-center">

                    <div className="box-item">

                        <i className="cci-invoice-02"></i>

                        <span>Bulk invoice printing</span>

                        <p>Allow yourself a well-earned break as we take the hassle out of manually printing individual invoices.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-discount-01"></i>

                        <span>Sales tax</span>

                        <p>Tax rate changes and locality are determined automatically. You can also set up your business as a sales-tax.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-check-03"></i>

                        <span>Fraud checks</span>

                        <p>he higher end packages include features that support users in identifying fraudulent orders.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-seo-01"></i>

                        <span>SEO</span>

                        <p>Search engine optimization fields for individual products help improve Google rankings.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-ssl-02"></i>

                        <span>Domain and SSL</span>

                        <p>All of our eCommerce packages include a free domain which is valid for the lifetime of your online store.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-email-04"></i>

                        <span>Hosting and mailboxes</span>

                        <p>Secure, georedundant hosting forms the core of our business. Many other providers will make you pay.</p>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">Are online stores only for professional sellers?</div>

                                <div className="box-item-body"><p>Although an attractive website and intuitive navigation are important when it comes to an online store builder, not to mention great deals and savvy marketing, the way you present your products should always be the main focus of your site. This means that your images must be high-quality with good lighting and must present the product in a positive way. Interesting and engaging product descriptions with as much detail as possible are also helpful for motivating potential customers to buy online from your eshop. For example, if you’re selling packs of items such as napkins or plates, then state exactly how many the customer will receive in the description.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">How much does the product cost?</div>

                                <div className="box-item-body"><p>You can purchase the entry level eCommerce shop package for the price of a movie ticket. While the highest package, costs the equivalent of a nice dinner out. Peanuts really.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">How quickly can I go live with this product?</div>

                                <div className="box-item-body"><p>Depending on the number of products in your shop, you can be online anywhere between a few hours or up to a day.</p></div>

                            </div>

                            <div role="contentinfo" className="box-item">

                                <div className="box-item-header">What payment methods can I offer my customers?</div>

                                <div className="box-item-body"><p>Adding as many payment methods as possible is incredibly beneficial and can often sway a customer towards purchasing from your ecommerce store. In addition to classic options like PayPal and credit/debit cards, there are several newer methods to choose from depending on your location and market. There are also payment methods like direct debit, purchase on account, and even Stripe and Bitcoin. PayPal Express Checkout is becoming a favored option for customers to shop online quickly by avoiding the need to enter log-in data.</p></div>

                            </div>

                        </div>

                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">

                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">

                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>);
}

export default ShopBuilder;