import { useEffect, useState } from "react";
import { ElementDrop } from "../elements/menu/element-drop";
import { appData } from "../app_data";
import { ContactMenu } from "../elements/menu/Contact";

const Above = () => {
    const [stickyTop, setStickyTop] = useState(false);
    const [headerContainer02Style, setHeaderContainer02Style] = useState({});

    // STARTING OVERLAY

    function overlay(status) {

        const overlay = document.createElement("div");
        overlay.setAttribute("class", "overlay");

        if (status === true) {

            document.body.style.overflow = "hidden";
            if (!document.querySelector(".overlay")) {

                document.body.prepend(overlay);

            }

        } else {

            document.body.removeAttribute("style");

            if (overlay) {

                document.body.querySelectorAll(".overlay").forEach(overlay => {

                    overlay.remove();

                });
            };

        };

    };

    // ENDING OVERLAY
    window.addEventListener("resize", e => {

        if (window.matchMedia("(min-width: 880px)").matches) {

            overlay(false);

        } else {

            if (document.querySelector(".menu-mobile-active")) {

                overlay(true);

            };

        };

    });

    const btnMenuMobileOpen = document.querySelectorAll('[data-open="menu-mobile"]');
    const btnMenuMobileClose = document.querySelectorAll('[data-close="menu-mobile"]');

    btnMenuMobileOpen.forEach(btnMobile => {

        const menuMobile = btnMobile.parentElement.querySelector(".menu-mobile");

        btnMobile.addEventListener("click", e => {

            overlay(true);

            menuMobile.classList.add("menu-mobile-active");

            setTimeout(() => {

                menuMobile.classList.add("menu-mobile-active-animation");

            }, 20);

        });

    });

    btnMenuMobileClose.forEach(btnMobile => {

        const menuMobile = btnMobile.parentElement.parentElement;

        btnMobile.addEventListener("click", e => {

            if (menuMobile.classList.contains("menu-mobile-active-animation")) {

                menuMobile.classList.remove("menu-mobile-active-animation");

            };

            setTimeout(() => {

                if (menuMobile.classList.contains("menu-mobile-active")) {

                    menuMobile.classList.remove("menu-mobile-active");

                };

                overlay(false);

            }, 100);

        });

    });

    useEffect(() => {
        new ElementDrop({
            active: true,
            parentEl: ".nav-menu-list",
            buttonEl: ".nav-title",
            parentElActive: ".nav-menu-list-active",
            parentElAnimation: {
                active: true,
                class: ".nav-menu-list-active-animation",
                closeAnimation: {
                    active: true,
                    time: 0,
                },
                time: 0,
            },
        });

        new ElementDrop({
            active: true,
            parentEl: ".box-item-body",
            buttonEl: ".box-item-header",
            parentElActive: ".question-active",
            parentElAnimation: {
                active: false,
                class: ".question-active-animation",
                closeAnimation: {
                    active: true,
                    time: 0,
                },
                time: 1000,
            },
        });
    }, []);

    //----------------
    document.querySelector("header");
    const btnScroll = document.createElement("button");
    btnScroll.id = "btn-scroll-top";
    btnScroll.setAttribute("class", "btn-scroll-top");
    btnScroll.setAttribute("aria-label", "Scroll to top");

    btnScroll.innerHTML = `<i class="cci-arrow-up-02"></i>`;

    const btnScrollTop = document.querySelector("#btn-scroll-top");
    //----------------

    window.addEventListener("scroll", e => {
        e.stopImmediatePropagation();

        if (window.scrollY >= 300) {
            setStickyTop(true);

            // document.body.style.paddingTop = `${header.querySelector(".container-01").clientHeight}px`;

            setTimeout(() => {

                // header.querySelector(".container-02").style.opacity = "1";
                setHeaderContainer02Style(prevState => ({ ...prevState, opacity: "1" }));

                setTimeout(() => {

                    // header.querySelector(".container-02").style.marginTop = "0";
                    setHeaderContainer02Style(prevState => ({ ...prevState, marginTop: "0" }));

                }, 300);

            }, 260);

            if (!btnScrollTop) {

                document.body.prepend(btnScroll);

                btnScroll.addEventListener("click", e => {

                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                });

            };

        } else {
            setStickyTop(false);

            document.body.removeAttribute("style");
            setHeaderContainer02Style({});

            if (btnScroll) {

                btnScroll.remove();

            };
        }
    })

    return (

        <header>
            <div className="container-01">
                <div className="container">
                    <div className="content-left">
                        <ul className="list-in-line">
                            {/* <li><i className="cci-phone-call-01"></i>+1-123-321-4321</li> */}
                            <li className="contact-email"><a href={`mailto:${appData.email}`}><i className="cci-envelope-01"></i>{appData.email}</a></li>
                        </ul>
                    </div>
                    <div className="content-right">
                        <button aria-label="open first menu mobile" data-open="menu-mobile"><i className="cci-menu-fill-01"></i></button>
                        <nav className="nav-in-line menu-mobile">
                            <div className="menu-mobile-header">
                                <a href="index.html" className="content-img logo"><img src={appData.logoURL} alt={appData.appName} /></a>
                                <button data-close="menu-mobile"><i className="cci-arrow-left-02"></i></button>
                            </div>
                            <ul>
                                <li>
                                    <div id="language-menu" className="nav-title bg-text-hover"><i className="cci-worldwide-01"></i>Language: English</div>
                                    <ul className="nav-menu nav-menu-list">
                                        <li><a href="/"><div className="content-img"><img src="/assets/img/country/226-united-states.svg" alt="United States Flag" /></div>United States - English <strong>(Selected)</strong></a></li>
                                        {/* <li><a href="/"><div className="content-img"><img src="/assets/img/country/255-brazil.svg" alt="Brazil Flag" /></div>Brazil - Portuguese</a></li>
                                        <li><a href="/"><div className="content-img"><img src="/assets/img/country/248-russia.svg" alt="Russia Flag" /></div>Russia - Russian</a></li>
                                        <li><a href="/"><div className="content-img"><img src="/assets/img/country/128-spain.svg" alt="Spain Flag" /></div>Spain - Spanish</a></li>
                                        <li><a href="/"><div className="content-img"><img src="/assets/img/country/034-china.svg" alt="China Flag" /></div>China - Chinese</a></li>
                                        <li><a href="/"><div className="content-img"><img src="/assets/img/country/063-japan.svg" alt="Japan Flag" /></div>Japan - Japanese</a></li> */}
                                    </ul>
                                </li>
                                <li><a href="https://client.rehostor.com/index.php?rp=/login"><i className="cci-padlock-01"></i>Login</a></li>
                                <li><a href="https://client.rehostor.com/cart.php?a=view"><i className="cci-cart-01"></i>Cart</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className={`container-02${stickyTop ? ' header-sticky' : ''}`} style={headerContainer02Style}>
                <div className="container">
                    <div className="content-left">
                        <a href="index.html" className="content-img logo"><img src={appData.logoURL} alt={appData.appName} /></a>
                    </div>
                    <div className="content-right">
                        <button aria-label="open second menu mobile" data-open="menu-mobile"><i className="cci-menu-01"></i></button>
                        <nav className="nav-in-line menu-mobile">
                            <div className="menu-mobile-header">
                                <a href="index.html" className="content-img logo"><img src={appData.logoURL} alt={appData.appName} /></a>
                                <button data-close="menu-mobile"><i className="cci-arrow-left-02"></i></button>
                            </div>
                            <ul className="icon-unset">
                                <li className="bg-text-hover"><a href="/" className="font-bold"><i className="cci-home-01 d=mp"></i>Home</a></li>
                                <li>

                                    <div className="nav-title bg-text-hover menu-title font-bold"><i className="cci-ssl-01"></i>Hosting & Server</div>

                                    <div className="nav-menu nav-menu-large nav-second-menu nav-menu-list">

                                        <div className="content-left">

                                            <div className="nav-menu-body">

                                                <div className="content-01">

                                                    <span className="h3">Hosting</span>

                                                    <ul className="list-in-column mt-4 mb-5">
                                                        <li><a href={appData.links.sharedHosting}><i className="cci-share-03"></i>Shared Hosting</a></li>
                                                        {/* <li><a href={appData.links.sharedHosting}><i className="cci-server-secure-01"></i>Private Hosting</a></li> */}
                                                        <li><a href={appData.links.siteBuilder}><i className="cci-design-01"></i>Website Builder</a></li>
                                                        {/* <li><a href={appData.links.sharedHosting}><i className="cci-online-shop-03"></i>Shop Builder</a></li> */}
                                                        <li><a href={appData.links.wordpressToolkit}><i className="cci-wordpress-01"></i>Wordpress Toolkit</a></li>
                                                        <li><a href={appData.links.sharedHosting}><i className="cci-joomla-01"> </i>Joomla Hosting</a></li>
                                                    </ul>

                                                </div>

                                                {/* <div className="content-02">

                                                    <span className="h3">Server</span>

                                                    <ul className="list-in-column mt-4">
                                                        <li><a href="vps-hosting.html"><i className="cci-vps-04"></i>VPS Hosting</a></li>
                                                        <li><a href="cloud-computing.html"><i className="cci-server-12"></i>Cloud Computing</a></li>
                                                        <li><a href="dedicated-server.html"><i className="cci-server-09"></i>Dedicated Server</a></li>
                                                        <li><a href="pay-per-use.html"><i className="cci-money-01"></i>Pay Per Use</a></li>
                                                    </ul>

                                                 </div> */}

                                            </div>

                                            <div className="nav-menu-footer"><span>If you need help, please</span> <a href="/contact-us">Contact Us</a></div>

                                        </div>

                                        <ContactMenu />

                                    </div>

                                </li>
                                <li>
                                    <div className="nav-title bg-text-hover menu-title font-bold"><i className="cci-script-01"></i>Services</div>
                                    <div className="nav-menu nav-menu-large nav-second-menu nav-menu-list">
                                        <div className="content-left">
                                            <div className="nav-menu-body">
                                                <div className="content-01">
                                                    <span className="h3">Services</span>
                                                    <ul className="list-in-column mt-4">
                                                        <li><a href={appData.links.siteBuilder}><i className="cci-design-01"></i>Web Design Services</a></li>
                                                    </ul>
                                                </div>
                                                {/* <div className="content-02">
                                                    <span className="h3">Buniness</span>
                                                    <ul className="list-in-column mt-4">
                                                        <li><a href="reseller-hosting.html"><i className="cci-ssl-02"></i>Reseller Hosting</a></li>
                                                        <li><a href="email.html"><i className="cci-email-02"></i>Email Hosting</a></li>
                                                        <li><a href="dedicated-server.html"><i className="cci-server-09"></i>Dedicated Server</a></li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                            <div className="nav-menu-footer"><span>If you need help, please</span> <a href="/contact-us">Contact Us</a></div>
                                        </div>
                                        <ContactMenu />
                                    </div>
                                </li>
                                <li>
                                    <div className="nav-title bg-text-hover menu-title font-bold"><i className="cci-ssl-01"></i>Domain & SSL</div>
                                    <div className="nav-menu nav-menu-large nav-second-menu nav-menu-list">
                                        <div className="content-left">
                                            <div className="nav-menu-body">
                                                <div className="content-01">
                                                    <span className="h3">Domain</span>
                                                    <ul className="list-in-column mt-4 mb-5">
                                                        <li><a href={appData.links.registerDomain}><i className="cci-www-01"></i>Register Domain</a></li>
                                                        <li><a href={appData.links.transferDomain}><i className="cci-transfer-02"></i>Transfer Domain</a></li>
                                                    </ul>

                                                </div>

                                                <div className="content-02">

                                                    <span className="h3">Security & Tools</span>

                                                    <ul className="list-in-column mt-4">
                                                        <li><a href={appData.links.ssl}><i className="cci-ssl-01"></i>SSL Certificate</a></li>
                                                    </ul>

                                                </div>

                                            </div>

                                            <div className="nav-menu-footer"><span>If you need help, please</span> <a href="/contact-us">Contact Us</a></div>

                                        </div>

                                        <ContactMenu />

                                    </div>

                                </li>

                                <li>

                                    <div className="nav-title bg-text-hover menu-title font-bold"><i className="cci-ssl-01"></i>Client Area</div>

                                    <div className="nav-menu nav-menu-large nav-second-menu nav-menu-list">

                                        <div className="content-left">

                                            <div className="nav-menu-body">

                                                <div className="content-01">

                                                    <span className="h3">Client Area</span>

                                                    <ul className="list-in-column mt-4 mb-5">
                                                        <li><a href={appData.links.login}><i className="cci-user-01"></i>Login</a></li>
                                                        <li><a href={appData.links.register}><i className="cci-user-02"></i>Register</a></li>
                                                        <li><a href="/"><i className="cci-target-02"></i>About Us</a></li>
                                                    </ul>

                                                </div>

                                                <div className="content-02">

                                                    <span className="h3">Legal</span>

                                                    <ul className="list-in-column mt-4">
                                                        <li className="bg-text-hover"><a href={appData.links.termsAndConditions}>Terms Of Service</a></li>
                                                        <li className="bg-text-hover"><a href={appData.links.privacyPolicy}>Privacy Policy</a></li>
                                                    </ul>

                                                </div>

                                            </div>

                                            <div className="nav-menu-footer"><span>If you need help, please</span> <a href="/contact_us">Contact Us</a></div>

                                        </div>

                                        <ContactMenu />

                                    </div>

                                </li>
                            </ul>

                        </nav>

                    </div>

                </div>

            </div>

        </header>

    );
}

export default Above;