export const appData = {
    appName: 'Rehostor',
    phone: '',
    email: 'info@rehostor.com',
    supportEmail: 'support@rehostor.com',
    baseURL: 'rehostor.com',
    fullURL: 'https://rehostor.com',
    logoURL: '/assets/img/logos/logo-white.svg',
    links: {
        sharedHosting: 'https://client.rehostor.com/index.php?rp=/store/shared-web-hosting',
        wordpressToolkit: 'https://client.rehostor.com/index.php?rp=/store/addon/feature/wp-toolkit-deluxe',
        registerDomain: 'https://client.rehostor.com/cart.php?a=add&domain=register',
        transferDomain: 'https://client.rehostor.com/cart.php?a=add&domain=transfer',
        siteBuilder: 'https://client.rehostor.com/index.php?rp=/store/site-builder',
        ssl: 'https://client.rehostor.com/index.php?rp=/store/ssl-certificates',
        login: 'https://client.rehostor.com/index.php?rp=/login',
        register: 'https://client.rehostor.com/register.php',
        contact: 'https://client.rehostor.com/submitticket.php?step=2&deptid=1',
        termsAndConditions: 'https://rehostor.com/termsandconditions/termsofservice',
        privacyPolicy: 'https://rehostor.com/termsandconditions/privacypolicy',
    }
}
