export const Partners = () => {
    return (
        <section aria-label="Partners" className="thumb-info mt-5">
            <div className="container">
                <div className="box partners partners-slider owl-carousel owl-theme">
                    <div className="content-img"><img src="/assets/img/design/cpanel-grey.svg" alt="cPanel Logo" /></div>
                    {/* <div className="content-img"><img src="/assets/img/design/whmcs-grey.svg" alt="whmcs Logo"/></div> */}
                    <div className="content-img"><img src="/assets/img/design/paypal-grey.svg" alt="paypal Logo" /></div>
                    <div className="content-img"><img src="/assets/img/design/wordpress-grey.svg" alt="wordpress Logo" /></div>
                    {/* <div className="content-img"><img src="/assets/img/design/joomla-grey.svg" alt="joomla Logo"/></div> */}
                    {/* <div className="content-img"><img src="/assets/img/design/crowbarcode-grey-01.svg" alt="crowbarcode Logo"/></div> */}
                </div>
            </div>
        </section>
    );
}

export const PartnersList = () => {
    return (
        <div className="partners">
            <div className="content-img"><img src="/assets/img/design/cpanel-grey.svg" alt="cPanel Logo" /></div>
            {/* <div className="content-img"><img src="/assets/img/design/whmcs-grey.svg" alt="whmcs Logo"/></div> */}
            <div className="content-img"><img src="/assets/img/design/paypal-grey.svg" alt="paypal Logo" /></div>
            <div className="content-img"><img src="/assets/img/design/wordpress-grey.svg" alt="wordpress Logo" /></div>
            {/* <div className="content-img"><img src="/assets/img/design/joomla-grey.svg" alt="joomla Logo"/></div> */}
            {/* <div className="content-img"><img src="/assets/img/design/crowbarcode-grey-01.svg" alt="crowbarcode Logo"/></div> */}
        </div>
    );
}