import { appData } from "../../app_data";
import { Partners } from "../../theme/Sections";

const TransferDomain = () => {
    return (
        <main>

        <section>

            <div className="container">

                <h1 className="h1">Transfer Your Domain</h1>

                <p>Enter the domain that you would like to transfer to {appData.appName}</p>

                <form id="domain-transfer" className="domain mb-5">

                    <input type="text" placeholder="Enter your domain"/>

                    <button className="btn btn-blue" type="submit">Transfer</button>

                </form>

                <p className="mt-10">Looking for a new domain name? <a href="domain-register.html">Try domain checker</a></p>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h2 className="h1">Transfer In 4 Simple Steps:</h2>

                <p>See some tips on how to successfully transfer your domain to us</p>

                <div className="box box-info box-four border-unset box-transparent">
    
                    <div className="box-item">

                        <span>Enter The Domain Name.</span>

                        <p>Simply enter your domain in field above name and click on Transfer.</p>

                    </div>

                    <div className="box-item">

                        <span>Proceed With The Purchase.</span>

                        <p>After the purchase you will be redirected to our members area.</p>

                    </div>

                    <div className="box-item">

                        <span>Enter The EPP Code.</span>

                        <p>You can obtain your EPP code from your current provider.</p>

                    </div>

                    <div className="box-item">

                        <span>Confirm Domain Transfer Email.</span>

                        <p>At this stage you must confirm the domain transfer in your email.</p>

                    </div>
    
                </div>

            </div>

        </section>

        <section>

            <div className="container">

                <h3 className="h1">Check this before:</h3>

                <p>Things To Check Before You Begin Transferring A Domain Name</p>

                <div className="box box-info box-two">

                    <div className="box-item">

                        <p>Check Before</p>

                        <span>First Check</span>

                        <p>Ensure that your domain is not within a redemption or deletion period. It must be active, and cannot be due to expire within 60 days. It must have been created more than 60 days prior.</p>

                    </div>

                    <div className="box-item">

                        <p>Check Before</p>

                        <span>Last Check</span>

                        <p>Ensure that the email addresses for the domain name holder and admin contact are valid, and visible on WHOIS (validation emails will be sent to these addresses).</p>

                    </div>


                </div>

            </div>

        </section>

        <section aria-label="Register Domain" className="bg-grey thumb-info">

            <div className="container">

                <div className="content-left text-left">

                    <span className="h1">If you are looking to register a new domain, click on the button beside;</span>
                    
                </div>

                <div className="content-right align-items-center justify-content-center">

                    <a href="domain-register.html" className="btn btn-large btn-blue">Register Domain</a>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is domain name?</div>

                                <div className="box-item-body"><p>A domain name is an identification string that defines a realm of administrative autonomy, authority or control within the Internet. Domain names are used in various networking contexts and for application-specific naming and addressing purposes. In general, a domain name identifies a network domain, or it represents an Internet Protocol (IP) resource, such as a personal computer used to access the Internet, a server computer hosting a website, or the web site itself or any other service communicated via the Internet. In 2017, 330.6 million domain names had been registered.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is purpose of domain name?</div>

                                <div className="box-item-body"><p>Domain names serve to identify Internet resources, such as computers, networks, and services, with a text-based label that is easier to memorize than the numerical addresses used in the Internet protocols. A domain name may represent entire collections of such resources or individual instances. Individual Internet host computers use domain names as host identifiers, also called hostnames. The term hostname is also used for the leaf labels in the domain name system, usually without further subordinate domain name space. Hostnames appear as a component in Uniform Resource Locators (URLs) for Internet resources such as websites (e.g., en.example.com).</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is history of domain name?</div>

                                <div className="box-item-body"><p>The practice of using a simple memorable abstraction of a host's numerical address on a computer network dates back to the ARPANET era, before the advent of today's commercial Internet. In the early network, each computer on the network retrieved the hosts file (host.txt) from a computer at SRI (now SRI International),[5][6] which mapped computer hostnames to numerical addresses. The rapid growth of the network made it impossible to maintain a centrally organized hostname registry and in 1983 the Domain Name System was introduced on the ARPANET and published by the Internet Engineering Task Force as RFC 882 and RFC 883.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is top-level domains?</div>

                                <div className="box-item-body"><p>TLD, or Top Level Domain, is a part of a website address that comes just after the last point of the URL. For better understanding, the TLD would be the exact location of a company, its full address. Some examples of TLD are . info, . com, . us, . co among others. Obviously, it is not on a random domain, its goal is to carry out a sort of ranking of the site. That is, a commercial site whose top-level Domain is . com is reporting that it is a commercial site. As an example we have Wordpress.com, which is a commercial site, while Wordpress.org is a non-profit version of the group.</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>
    );
}

export default TransferDomain;