import { appData } from "../../app_data";
import { Partners } from "../../theme/Sections";

const About = () => {
    return (
        <main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Discover our entire trajectory</span>

                        <span className="h2">Learn more about {appData.appName}</span>

                        <p>Founded in 2015 by Adrian Shepard, {appData.appName} has come a long way from its beginnings in United States. When Adrian Shepard and group a friends first started out passion hosting. This drove them to start their own business called {appData.appName}.</p>
                        
                        <div className="btn-group">

                            <a className="btn btn-blue" href="/timeline">Our timeline</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/team-02.svg" alt="Team Banner"/></div>

                </div>

            </div>

        </div>

        <section aria-label="About Us" className="mb-5">

            <div className="container">

                <div className="side-by-side">

                    <div className="content-left" data-aos="fade-right">

                        <div className="content-img content-img-reverse content-img-borded"><img src="/assets/img/design/company.jpg" alt="IMG Description"/></div>
                        
                    </div>

                    <div className="content-right" data-aos="fade-left">

                        <span className="h2">About Us</span>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, beatae. At voluptate deleniti ex consequuntur libero voluptatum illo reiciendis, tempora expedita harum laborum ad fugit alias nam atque inventore saepe.</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, beatae. At voluptate deleniti ex consequuntur libero voluptatum illo reiciendis, tempora expedita harum laborum ad fugit alias nam atque inventore saepe.</p>

                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, beatae. At voluptate deleniti ex consequuntur libero voluptatum.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/timeline">Our timeline</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

        </section>

        <section id="timeline" aria-label="See our story so far in the timeline" className="bg-grey">

            <div className="container">

                <div className="timeline">

                    <div className="container" data-aos="fade-up">
                      <div className="date">2021</div>
                      <i className="icon cci-user-04"></i>
                      <div className="content bg-bd-hover">
                        <h2>2021</h2>
                        <p>In 2021 we reached the mark of 136 thousand customers. 100 thousand clients in hosting services. 92 thousand registered domains and 26 thousand online client servers. In 2021 we delivered a new appearance to the {appData.appName} website.</p>
                      </div>
                    </div>
                    <div className="container">
                        <div className="date">2020</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2020</h2>
                          <p>In 2020 we reached the mark of 112 thousand customers. 80 thousand clients in hosting services. 45 thousand registered domains and 12 thousand online client servers. In the year 2020 we expanded our offices to various cities around the world.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="date">2019</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2019</h2>
                          <p>In 2019 we reached the mark of 40 thousand customers. 35 thousand customers in hosting services 2 thousand registered domains and 5 thousand online client servers. In 2019 we expanded all the resources of all our servers.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="date">2018</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2018</h2>
                          <p>In 2018 we reached the mark of 20 thousand customers. 18 thousand customers in hosting services 1 thousand registered domains and 1 thousand online client servers. In 2018, we invested in more servers.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="date">2017</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2017</h2>
                          <p>In the year 2017 we expanded our company to more than 5 countries being them United States, Brazil, Espanha, Russia, china and japan the entire site was translated into the language of each country and hire employees from each country.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="date">2016</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2016</h2>
                          <p>This year 2016 we started working hard. We hire more qualified people for support and service services, invest a lot of money in servers and are thinking about launching the company worldwide.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="date">2015</div>
                        <i className="icon cci-user-04"></i>
                        <div className="content bg-bd-hover">
                          <h2>2015</h2>
                          <p>In 2015 a group of friends interested in succeeding in life decided to discuss a business idea at Adrian’s house. Shepard one of the founders of the {appData.appName} company. We think of various ideas, the more we decided to put into practice, was the hosting business.</p>
                        </div>
                    </div>

                  </div>

            </div>

        </section>

        <section id="founders">

            <div className="container">
                
                <h2 className="h1">Our team of founders</h2>

                <p className="txt-large">See which people had the idea and founded the {appData.appName} company</p>
    
                <div className="box box-info box-customer">
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/08m.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Adrian Shepard</span>
    
                        <cite className="title txt-light">Owner / Founder</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>Learn to enjoy every minute of your life. Be happy now. Don’t wait for something outside of yourself to make you happy in the future. Think how really precious is the time you have to spend, whether it’s at work or with your family. Every minute should be enjoyed and savored.</p>
    
                    </div>
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/31m.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Edward Izaac</span>
    
                        <cite className="title txt-light">Owner / Founder</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>Peace is the beauty of life. It is sunshine. It is the smile of a child, the love of a mother, the joy of a father, the togetherness of a family. It is the advancement of man, the victory of a just cause, the triumph of truth. I think being in love with life is a key to eternal youth.</p>
    
                    </div>
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/18w.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Janaina B. Goncalves</span>
    
                        <cite className="title txt-light">Owner / Founder</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>Life is full of beauty. Notice it. Notice the bumble bee, the small child, and the smiling faces. Smell the rain, and feel the wind. Live your life to the fullest potential, and fight for your dreams. Dwell on the beauty of life. Watch the stars, and see yourself running with them.</p>
    
                    </div>
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/02m.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Thomas M. Holman</span>
    
                        <cite className="title txt-light">SEO / Marketing</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>To be creative means to be in love with life. You can be creative only if you love life enough that you want to enhance its beauty, you want to bring a little more music to it, a little more poetry to it, a little more dance to it. Unexpected intrusions of beauty. This is what life is.</p>
    
                    </div>
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/41w.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Jennifer D. Stone</span>
    
                        <cite className="title txt-light">Ideas / Design</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>Sometimes beautiful things come into our lives out of nowhere. We can’t always understand them, but we have to trust them. I know you want to question everything, but sometimes it pays to just have a little faith. When one has a grateful heart, life is so beautiful.</p>
    
                    </div>
    
                    <div className="box-item">
    
                        <div className="content-img"><img src="/assets/img/profile/45m.jpg" width="250" height="250" alt="People Profile"/></div>
    
                        <span className="title t-small mb-unset">Mark A. Farrell</span>
    
                        <cite className="title txt-light">Developer / Design</cite>
    
                        <nav>

                            <ul className="list-icons list-in-row list-in-row-right">
                                <li className="bg-text-hover"><a aria-label="Facebook" href="/"><i className="cci-facebook-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Twitter" href="/"><i className="cci-twitter-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Instagram" href="/"><i className="cci-instagram-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Youtube" href="/"><i className="cci-youtube-fill-01"></i></a></li>
                                <li className="bg-text-hover"><a aria-label="Whatsapp" href="/"><i className="cci-whatsapp-fill-01"></i></a></li>
                            </ul>
    
                        </nav>
    
                        <p>Today I choose life. Every morning when I wake up I can choose joy, happiness, negativity, pain… To feel the freedom that comes from being able to continue to make mistakes and choices – today I choose to feel life, not to deny my humanity but embrace it.</p>
    
                    </div>
    
                </div>

            </div>

        </section>

        <section className="bg-grey">

            <div className="container">

                <h3 className="h1">Let’s Grow Together</h3>

                <p>At {appData.appName} we grew up together. Join our customers and have a successful website!</p>

                <div className="box box-info-small">
    
                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-server-01"></i>

                            <span className="h2">100.00</span>

                        </div>

                        <p className="font-bold h3">Hosting Contract</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-www-01"></i>

                            <span className="h2">92.00</span>

                        </div>

                        <p className="font-bold h3">Domains Registered</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-server-03"></i>

                            <span className="h2">26.00</span>

                        </div>

                        <p className="font-bold h3">Servers Contract</p>

                    </div>

                    <div className="box-item borded bg-bd-hover">

                        <div className="box-header">

                            <i className="cci-user-04"></i>

                            <span className="h2">136.00</span>

                        </div>

                        <p className="font-bold h3">Total Clients</p>

                    </div>
    
                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is Shared Hosting and why I should choose?</div>

                                <div className="box-item-body"><p>Shared web hosting service is a web hosting service where many websites reside on one web server connected to the Internet. This is generally the most economical option for hosting, as the overall cost of server maintenance is spread over many customers. By choosing shared hosting, the website will share a physical server with one or more other websites. The service must include system administration since it is shared by many users; this is a benefit for users who do not want to deal with it, but a hindrance to power users who want more control. In general shared hosting will be inappropriate for users who require extensive software development outside what the hosting provider supports. Almost all applications intended to be on a standard web server work fine with a shared web hosting service. But on the other hand, shared hosting is cheaper than other types of hosting such as dedicated server hosting.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a VPS server and why should I choose?</div>

                                <div className="box-item-body"><p>A virtual private server (VPS) is a virtual machine sold as a service by an Internet hosting service. The virtual dedicated server (VDS) also works in a virtualized environment but some CPU cores are dedicated for it, which is not the case with VPS. A virtual private server runs its own copy of an operating system (OS), and customers may have superuser-level access to that operating system instance, so they can install almost any software that runs on that OS. For many purposes it is functionally equivalent to a dedicated physical server and, being software-defined, can much more easily be created and configured. A virtual server costs much less than an equivalent physical server. However, as virtual servers share the underlying physical hardware with other VPSes, performance may be lower, depending on the workload of any other executing virtual machines</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a Cloud Computing and why I should choose?</div>

                                <div className="box-item-body"><p>Cloud computing is the on-demand availability of computer system resources, especially data storage (cloud storage) and computing power, without direct active management by the user. The term is generally used to describe data centers available to many users over the Internet. Large clouds, predominant today, often have functions distributed over multiple locations from central servers. If the connection to the user is relatively close, it may be designated an edge server. Clouds may be limited to a single organization (enterprise clouds), or be available to multiple organizations (public cloud). Cloud computing relies on sharing of resources to achieve coherence and economies of scale.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is a Dedicated Server and why I should choose?</div>

                                <div className="box-item-body"><p>A dedicated hosting service, dedicated server, or managed hosting service is a type of Internet hosting in which the client leases an entire server not shared with anyone else. This is more flexible than shared hosting, as organizations have full control over the server(s), including choice of operating system, hardware, etc. There is also another level of dedicated or managed hosting commonly referred to as complex managed hosting. Complex Managed Hosting applies to both physical dedicated servers, Hybrid server and virtual servers, with many companies choosing a hybrid (combination of physical and virtual) hosting solution.</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

       <Partners />

    </main>
    );
}

export default About;