import { Partners } from "../../theme/Sections";

const WordpressHosting = () => {
    return (<main>

        <div className="thumb thumb-blue-dark">

            <div className="container">

                <div className="content-left">

                    <div className="content-text">

                        <span className="h3 font-light">Wordpress Hosting</span>

                        <span className="h2">Ultra fast WordPress hosting</span>

                        <p>We've curated the best possible features to optimize your new WordPress site: hand-selected themes, pre-installed plugins, and even a fully customized WordPress control panel.</p>
                        
                        <div className="btn-group">

                            <a className="btn btn-blue" href="/plans">See all plans</a>
                            <a className="btn btn-grey" href="contact-us.html">Contact Us</a>

                        </div>

                    </div>

                </div>

                <div className="content-right">

                    <div className="content-img content-img-small"><img src="/assets/img/design/wordpress.svg" alt="Wordpress Banner"/></div>

                </div>

            </div>

        </div>

        <section>

            <div className="container">

                <h1 className="h1">WordPress Hosting Features</h1>

                <p>All the WordPress hosting features you need in one convenient place.</p>

                <div className="box box-info justify-center">

                    <div className="box-item">

                        <i className="cci-bot-02"></i>
                        
                        <span>Automatic WordPress core and security updates</span>

                        <p>We take the chore of WordPress core updates away from you. We monitor updates closely and roll them out as soon as we’re sure there are no bugs linked to them.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-backup-01"></i>
                        
                        <span>Daily backups and with 1-click restore</span>

                        <p>We safeguard your site with automatic backups that are kept for 30 days. A single stress-free click is all it takes to restore your full site, or a part of it such as your databases or files.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-ssl-02"></i>
                        
                        <span>Free pre-installed SSL</span>

                        <p>The website linked to your Managed WordPress hosting package will be automatically secured with a valid DV SSL certificate. You don’t need to worry about validating it or renewing it. Our system does that for you.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-spped-01"></i>
                        
                        <span>Website performance</span>

                        <p>This handy tool analyses your site to give you deep insights into any factors that could be impacting its performance. It takes into account everything from image size to text compression to help you understand how to speed up your site.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-wordpress-01"></i>
                        
                        <span>An Optimized WordPress Experience</span>

                        <p>Our finely tuned WordPress optimized servers offer your website improved reliability, stability and security - an ideal experience for your visitors. That’s why we give you the 30-day guarantee.</p>

                    </div>

                    <div className="box-item">

                        <i className="cci-folder-01"></i>
                        
                        <span>File Manager</span>

                        <p>Our in-house File Browser allows you to upload, create, and manage files on your Managed WordPress hosting. It can also be used in place of FTP to edit PHP INI files or upload compressed files to your storage.</p>

                    </div>

                </div>

            </div>

        </section>

        <section id="plans" className="bg-grey">

            <div className="container">
                
                <h2 className="h1">Choose A Wordpress Hosting Plan</h2>

                <p className="txt-large">Our WordPress hosting plans gives you everything you need to build the perfect site</p>
    
                <div className="box box-info box-price price-style-01 box-three">

                    <div className="box-item scale-hover">

                        <span>Starter Wordpress</span>

                        <div className="price-value"><span>$</span><cite>1.66</cite><span>/month</span></div>

                        <p>You pay $2.17 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>10GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>5 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>WP-CLI</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>20MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>200 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Advanced Wordpress</span>

                        <div className="price-value"><span>$</span><cite>3.98</cite><span>/month</span></div>

                        <p>You pay $4.98 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>100GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>15 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>WP-CLI</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>40MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>400 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-uncheck-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                    <div className="box-item scale-hover">

                        <span>Premium Wordpress</span>

                        <div className="price-value"><span>$</span><cite>4.93</cite><span>/month</span></div>

                        <p>You pay $6.17 on next renewal</p>
                        
                        <a href="/" className="btn btn-blue-01">Add to cart</a>

                        <ul>

                            <li><i className="cci-check-03"></i> <span>500GB SSD Disk</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited monthly bandwidth</span></li>
                            <li><i className="cci-check-03"></i> <span>LiteSpeed + LSCache</span></li>
                            <li><i className="cci-check-03"></i> <span>30 addon domains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited subdomains</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited email accounts</span></li>
                            <li><i className="cci-check-03"></i> <span>Unlimited MySQL databases</span></li>
                            <li><i className="cci-check-03"></i> <span>CloudFlare CDN</span></li>
                            <li><i className="cci-check-03"></i> <span>WP-CLI</span></li>
                            <li><i className="cci-check-03"></i> <span>200% CPU</span></li>
                            <li><i className="cci-check-03"></i> <span>40MB/s IO</span></li>
                            <li><i className="cci-check-03"></i> <span>400 Processes</span></li>
                            <li><i className="cci-check-03"></i> <span>SSL Certificate free</span></li>
                            <li><i className="cci-check-03"></i> <span>Domain 1 year free</span></li>

                        </ul>

                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Hosting Features">

            <div className="container">

                <div className="side-by-side">

                    <div className="content-left">
    
                        <div className="content-img-small reverse"><img src="/assets/img/design/website-builder-07.svg" alt="Website Builde"/></div>

                    </div>

                    <div className="content-right content-text">
        
                        <span className="title t-extra-large txt-black">Built-in function and style</span>

                        <p className="txt-large txt-black">We've curated the best possible features to optimize your new WordPress site: hand-selected themes, pre-installed plugins, and even a fully customized WordPress control panel.</p>
    
                    </div>

                </div>

                <div className="side-by-side side-by-side-reverse">

                    <div className="content-left">
    
                        <div className="content-img-small reverse"><img src="/assets/img/design/private-01.svg" alt="Private"/></div>

                    </div>

                    <div className="content-right content-text">
        
                        <span className="title t-extra-large txt-black">Speed and security</span>

                        <p className="txt-large txt-black">Our WordPress platform has been designed to increase load speeds by up to 2.5 times. We've also teamed up with SiteLock to deliver a deluxe security bundle, and included a custom firewall.</p>
    
                    </div>

                </div>

                <div className="side-by-side">

                    <div className="content-left">
    
                        <div className="content-img-small reverse"><img src="/assets/img/design/support-03.svg" alt="Support"/></div>

                    </div>

                    <div className="content-right content-text">
        
                        <span className="title t-extra-large txt-black">WordPress Support</span>

                        <p className="txt-large txt-black">Our team of specially-trained support agents are experts in all things WordPress. You'll be taken care of efficiently and effectively by helpful and knowledgeable individuals.</p>
    
                    </div>

                </div>

            </div>

        </section>

        <section aria-label="Contact Us" className="bg-grey thumb-info">

            <div className="container">

                <div className="content-left text-left">

                    <span className="h1">See you are in need of help. Contact us immediately.</span>
                    
                </div>

                <div className="content-right align-items-center justify-content-center">

                    <a href="contact-us.html" className="btn btn-large btn-blue">Contact Us</a>

                </div>

            </div>

        </section>

        <section aria-label="Question">

            <div className="container">

                <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                    <div className="content-left m-unset">

                        <div className="box questions">

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is hosting?</div>

                                <div className="box-item-body"><p>Let's break it down. The basics: you want a website. You've got great ideas for content, a vision board describing your site's mood, and even a wishlist for additional functions you want for your website. But how exactly do you get your site online? That's where hosting comes in. Your website needs a hosting provider to get it up on the web and publicly available to visit. Web hosts are companies that provide the server space necessary to store your website. As web traffic attempts to access your site, the necessary data is transmitted from the server to the visitors' device. Web hosting providers do, however, differ in the variety of plan types and options they offer. So having a quality web host is crucial.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">What is WordPress hosting?</div>

                                <div className="box-item-body"><p>WordPress hosting is a semi-dedicated hosting designed for all our customers who want to use WordPress as a CMS. WordPress is an open-source CMS, the most used at the moment for creating websites. Thanks to the multitude of themes and plugins available, you can effortlessly create your website from an intuitive and easy-to-use control panel.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">Is there a cache system with WordPress hosting?</div>

                                <div className="box-item-body"><p>Yes, with WordPress hosting is available LiteSpeed ​​+ LSCache (in addition to OPcache and Memcached), which allows us to give maximum performance to your site created with WordPress. From our tests, Litespeed + LSCache perform 20-30% better than a standard Apache hosting.</p></div>

                            </div>

                            <div  role="contentinfo" className="box-item">

                                <div className="box-item-header">Can I get help with my website transfer?</div>

                                <div className="box-item-body"><p>Yes, we can transfer your website to our WordPress hosting. The transfer of your website is included within the price of the annual hosting plan. Once you have purchased a plan, an automatic ticket will open to request your access data to the old hosting provider.</p></div>

                            </div>
                            
                        </div>
                        
                    </div>

                    <div className="content-right m-unset">

                        <span className="h2">List of questions</span>

                        <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                        <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                        <div className="btn-group">
    
                            <a className="btn btn-blue" href="/">Helpdesk</a>
                            <a className="btn btn-grey" href="/">Contact Us</a>

                        </div>

                    </div>

                </div>

            </div>

            <div className="container d-none">

                <h5 className="h1">Third Title Page</h5>

                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                <div className="box box-info box-price mb-5">
    
                    <div className="box-item">

                        <span>Standard</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Medium</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem dolor </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>

                    <div className="box-item">

                        <span>Advanced</span>

                        <p>Lorem, ipsum dolor sit</p>

                        <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                        <p>Lorem, ipsum dolor sit Lorem </p>

                        <a href="/" className="btn btn-blue">Add to cart</a>

                        <ul className="list-in-column">
                            <li><i className="cci-check-01"></i> Feature 01</li>
                            <li><i className="cci-check-01"></i> Feature 02</li>
                            <li><i className="cci-check-01"></i> Feature 03</li>
                            <li><i className="cci-check-01"></i> Feature 04</li>
                            <li><i className="cci-check-01"></i> Feature 05</li>
                            <li><i className="cci-uncheck-01"></i> Feature 06</li>
                            <li><i className="cci-uncheck-01"></i> Feature 07</li>
                            <li><i className="cci-uncheck-01"></i> Feature 08</li>
                        </ul>

                    </div>
    
                </div>

                <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

            </div>

        </section>

        <Partners />

    </main>);
}

export default WordpressHosting;