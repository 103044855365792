export class Slider {

    constructor(options) {
        this._sliderGlobalOpt = options;
        this._sliderParent = options.sliderParent;
        if(document.querySelector(this._sliderParent) === null) return false;
        this._sliderItems = null;
        this._sliderItem = options.sliderChild;
        this._sliderSubParent = options.sliderSubParent;
        this._sliderControl = options.sliderControl
        this._sliderActive = options.sliderActive;
        this._sliderBtnActive = options.sliderBtnActive.replace(/[., #]/g, "");
        this._sliderContainer = (options.sliderContainer) ? options.sliderContainer.replace(/[., #]/g, "") : null;
        this._currentSlider = 0;
        this._sliderShow = this._sliderGlobalOpt.show;
        this._sliderTimer = {sliderParent: this._sliderParent.replace(/[., #]/g, "")};
        if(options.active === true) this.init();
    };

    init() {

        const sliderItems = document.createElement("div");
        const sliderContainer = document.createElement("div");
        sliderItems.setAttribute("class", this._sliderSubParent.replace(/[., #]/g, ""));

        this._sliderTimer[this._sliderTimer.sliderParent] = null;

        document.querySelectorAll(`${this._sliderParent} ${this._sliderItem}`).forEach(slider => {

            sliderItems.appendChild(slider)

        });

        if(this._sliderContainer) {

            sliderContainer.setAttribute("class", this._sliderContainer);
            sliderContainer.style.overflow = "hidden";

            sliderContainer.appendChild(sliderItems);

            document.querySelector(this._sliderParent).appendChild(sliderContainer);

        } else {

            document.querySelector(this._sliderParent).appendChild(sliderItems);

        };

        this._sliderItems = document.querySelector(`${this._sliderParent} ${this._sliderSubParent}`).children;

        this.showQtd();

        this.insertButton(this._sliderItems.length);

    };

    insertButton(btnQtd) {

        const sliderControl = document.createElement("div");
        const sliderBtnUl = document.createElement("ul");
        sliderControl.setAttribute("class", this._sliderControl.replace(/[., #]/g, ""));

        if(this._sliderGlobalOpt.control === false) {

            sliderControl.style.display = "none";

        };

        for(let i = 0; i < btnQtd; i++) {

            const sliderBtnLi = document.createElement("li");
            const sliderBtn = document.createElement("button");

            sliderBtn.dataset.slider = i;
            sliderBtn.setAttribute("aria-label", `Slider Button ${i}`);

            sliderBtnLi.appendChild(sliderBtn)
            sliderBtnUl.appendChild(sliderBtnLi);

        };

        sliderControl.appendChild(sliderBtnUl);

        if(btnQtd > 1) {

            document.querySelector(this._sliderParent).appendChild(sliderControl);

        };

        this.controller(sliderBtnUl);

    };

    showQtd() {

        if(this._sliderShow) {

            if(this._sliderGlobalOpt.responsive) {

                Object.keys(this._sliderGlobalOpt.responsive).reverse().forEach(key => {
    
                    if(window.innerWidth <= parseInt(key)) {
    
                        this._sliderShow = this._sliderGlobalOpt.responsive[key];
    
                    };
    
                });
                
            };
    // eslint-disable-next-line 
            Object.defineProperty(Array.prototype, 'crowbarcode_chunk', {
                value: function(chunkSize) {
                  var array = this;
                  return [].concat.apply([],
                    array.map(function(elem, i) {
                      return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
                    })
                  );
                }
            });
    
            const newSliderItems = [...this._sliderItems].map((sliderItem, index) => {
    
                sliderItem.setAttribute("class", `${this._sliderItem.replace(/[., #]/g, "")}-${index+1}`);
    
                return sliderItem;
    
            });
    
            newSliderItems.crowbarcode_chunk(this._sliderShow).forEach(sliderItemsArray => {
    
                const sliderItemEl = document.createElement("div");
                sliderItemEl.setAttribute("class", this._sliderItem.replace(/[., #]/g, ""));
    
                sliderItemsArray.forEach(sliderItem => {
                    
                    sliderItemEl.appendChild(sliderItem);
    
                    document.querySelector(`${this._sliderParent} ${this._sliderSubParent}`).appendChild(sliderItemEl)
                    
                });
    
            });
    
            document.querySelectorAll(`${this._sliderParent} ${this._sliderSubParent} ${this._sliderItem}`)[0].classList.add(this._sliderActive.replace(/[., #]/g, ""));
 
        };

    };

    controller(sliderBtnUl) {

        const btns = sliderBtnUl.querySelectorAll("ul button");
        btns[0].classList.add(this._sliderBtnActive);
        // eslint-disable-next-line 
        let autoPlay = null;
        
        // eslint-disable-next-line 
        const sliderActive = document.querySelector(`${this._sliderParent} ${this._sliderActive}`);

        btns.forEach(btn => {

            btn.addEventListener("click", e=> {

                const btnSlider = parseInt(e.target.dataset.slider);
                const slider = this._sliderItems[btnSlider];

                this.removeBtnActive(btns, e.target);

                if(btnSlider !== this._currentSlider) {

                    if(btnSlider <= this._currentSlider) {

                        this.sliderDir({
                            sliderActive: slider,
                            to: "100%",
                            from: "-100%",
                        });
    
                    } else {

                        this.sliderDir({
                            sliderActive: slider,
                            to: "-100%",
                            from: "100%",
                        });

                    };

                };

                this.autoPlay(btns, e.target);

                this._currentSlider = btnSlider;

                btns.forEach(btn => {

                    btn.disabled = false;

                });

            });

        });

        this.autoPlay(btns, btns[0]);

    };

    removeBtnActive(btns, currentBtn) {

        btns.forEach(btn => {

            if(btn !== currentBtn) {

                if(btn.classList.contains(this._sliderBtnActive)) {

                    btn.classList.remove(this._sliderBtnActive);
    
                    btn.disabled = false;
                };

            };
    
        });

    };

    autoPlay(btns, currentBtn) {

        if(btns.length > 1) {

            if(this._sliderGlobalOpt.autoPlay.active === true) {

                this._currentSlider = parseInt(currentBtn.dataset.slider);
            
                if(this._sliderTimer[this._sliderTimer.sliderParent]) {
        
                    clearInterval(this._sliderTimer[this._sliderTimer.sliderParent]);
        
                };
        
                this._sliderTimer[this._sliderTimer.sliderParent] = setInterval(() => {
        
                    if(document.querySelector(".menu-mobile-active")) {

                        return false;

                    };

                    this.removeBtnActive(btns, null);
        
                    let slider = document.querySelectorAll(`${this._sliderParent} ${this._sliderItem}`)[this._currentSlider].nextElementSibling;
        
                    if(slider === null) {
        
                        slider = document.querySelectorAll(`${this._sliderParent} ${this._sliderItem}`)[0];
        
                    };
        
                    this.sliderDir({
                        sliderActive: slider,
                        to: "-100%",
                        from: "100%",
                    });
        
                    this._currentSlider++;
        
                    if(this._currentSlider >= btns.length) {
        
                        this._currentSlider = 0;
        
                    };
        
                }, this._sliderGlobalOpt.autoPlay.time);
    
            };

        };

    };

    sliderDir(dirOpt) {

        const sliderBtn = document.querySelectorAll(`${this._sliderParent} ${this._sliderControl} ul button`);

        [...this._sliderItems].forEach((slider, index) => {

            if(slider.classList.contains(this._sliderActive.replace(/[., #]/g, ""))) {

                slider.style.marginLeft = dirOpt.to;

            };

            if(slider === dirOpt.sliderActive) {

                sliderBtn[index].classList.add(this._sliderBtnActive);

            };

        });

        setTimeout(() => {
            
            [...this._sliderItems].forEach((slider, index) => {

                if(slider.classList.contains(this._sliderActive.replace(/[., #]/g, ""))) {

                    slider.classList.remove(this._sliderActive.replace(/[., #]/g, ""));
                    slider.style.marginLeft = "unset";

                };

            });
            
            dirOpt.sliderActive.classList.add(this._sliderActive.replace(/[., #]/g, ""));
            dirOpt.sliderActive.style.marginLeft = dirOpt.from;

            setTimeout(() => {
                
                dirOpt.sliderActive.style.marginLeft = "unset";

            }, this._sliderGlobalOpt.time);

        }, this._sliderGlobalOpt.time);

    };

};