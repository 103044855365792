import Above from "./Above";
import Below from "./Below";
import { Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <Above />
            <Outlet />
            <Below />
        </>
    );
}

export default Layout;