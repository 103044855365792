import { Outlet } from "react-router-dom";
import Above from "../../theme/Above";
import Below from "../../theme/Below";

const Hosting = () => {

    return (
        <>
            <Above />
            <Outlet />
            <Below />
        </>
    );
}

export default Hosting;