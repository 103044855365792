import { Partners } from "../../theme/Sections";

const Contact = () => {
    return (
        <main>

            <div className="thumb thumb-blue-dark">

                <div className="container">

                    <div className="content-left">

                        <div className="content-text">

                            <span className="h3 font-light">We're here to help</span>

                            <span className="h2">Get In Touch With Us</span>

                            <p>All of our attendants are trained and love to help people like you, either to reset your password or to create your entire online experience. Contact us via the contact form on this page. Our response time is 30 minutes.</p>

                            <div className="btn-group">

                                <a className="btn btn-blue" href="/contact-us">Contact Us</a>
                                <a className="btn btn-grey" href="/ticket">Open A Ticket</a>

                            </div>

                        </div>

                    </div>

                    <div className="content-right">

                        <div className="content-img content-img-small"><img src="/assets/img/design/support-02.svg" alt="Support Banner" /></div>

                    </div>

                </div>

            </div>

            <section id="contact">

                <div className="container">

                    <h1 className="h1">Choose A Contact Form Below!</h1>

                    <p className="txt-large">Below we list some ways to contact us. Our average response time is 30 minutes</p>

                    <div className="box box-info">

                        <a href="/contact-form" className="box-item">

                            <i className="cci-complain-01"></i>

                            <span>Contact form</span>

                            <p>This method is more common. Just click on this box we will redirect you to the form. You need to fill in your full name, phone number, email address, reason for contact and finally your message.</p>

                        </a>

                        <a href="tel:+11233214321" className="box-item">

                            <i className="cci-phone-02"></i>

                            <span>Contact by telephone</span>

                            <p>If you need a faster response. The method of contact by phone is for you. You can contact us via phone and chat with one of our support or sales agents. Call number +1-123-321-4321</p>

                        </a>

                        <a href="/" className="box-item">

                            <i className="cci-chat-05"></i>

                            <span>Chat Online</span>

                            <p>Besides the phone, you can also get a faster answer through our online chat. If you have a question and need quick answers. Use our online chat 24/7/365 just click on this box.</p>

                        </a>

                    </div>

                </div>

            </section>

            <section aria-label="Contact Form" id="contact-form" className="bg-grey">

                <div className="container">

                    <form id="form-contact" className="form-01 w-100 bg-white">

                        <div className="content-text">

                            <h2 className="title t-large txt-black">Contact form!</h2>

                            <p className="txt-large">Fill in the form below to send your message.</p>

                        </div>

                        <div className="input-group">

                            <fieldset>

                                <label htmlFor="fname">First Name</label>

                                <input id="fname" name="firstName" className="input-modern" type="text" />

                            </fieldset>

                            <fieldset>

                                <label htmlFor="lname">Last Name</label>

                                <input id="lname" name="lastName" className="input-modern" type="text" />

                            </fieldset>

                        </div>

                        <div className="input-group">

                            <fieldset>

                                <label htmlFor="phone">Phone</label>

                                <input id="phone" name="phone" className="input-modern" type="text" />

                            </fieldset>

                            <fieldset>

                                <label htmlFor="email">Email</label>

                                <input id="email" name="email" className="input-modern" type="text" />

                            </fieldset>

                        </div>

                        <fieldset>

                            <label htmlFor="subject">Subject</label>

                            <input id="subject" name="subject" className="input-modern" type="text" />

                        </fieldset>

                        <fieldset>

                            <label htmlFor="departament">Department</label>

                            <select name="departament" id="departament" className="input-modern">
                                <option value="informations">Informations</option>
                                <option value="support">Support</option>
                                <option value="sales">Sales</option>
                                <option value="finance">Finance</option>
                                <option value="seo">SEO</option>
                                <option value="abuse">Abuse</option>
                                <option value="job">Job</option>
                                <option value="request-quote">Request Quote</option>
                                <option value="suggest">Suggestions / Ideas</option>
                            </select>

                        </fieldset>

                        <fieldset>

                            <label htmlFor="message">Message</label>

                            <textarea className="input-modern" name="message" id="message" cols="30" rows="10"></textarea>

                        </fieldset>

                        <button type="submit" className="btn btn-large btn-blue d-flex">Send a message</button>

                    </form>

                </div>

            </section>

            <section aria-label="Question">

                <div className="container">

                    <div className="side-by-side side-by-side-reverse mw-100 side-by-side-question">

                        <div className="content-left m-unset">

                            <div className="box questions">

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is Shared Hosting and why I should choose?</div>

                                    <div className="box-item-body"><p>Shared web hosting service is a web hosting service where many websites reside on one web server connected to the Internet. This is generally the most economical option for hosting, as the overall cost of server maintenance is spread over many customers. By choosing shared hosting, the website will share a physical server with one or more other websites. The service must include system administration since it is shared by many users; this is a benefit for users who do not want to deal with it, but a hindrance to power users who want more control. In general shared hosting will be inappropriate for users who require extensive software development outside what the hosting provider supports. Almost all applications intended to be on a standard web server work fine with a shared web hosting service. But on the other hand, shared hosting is cheaper than other types of hosting such as dedicated server hosting.</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a VPS server and why should I choose?</div>

                                    <div className="box-item-body"><p>A virtual private server (VPS) is a virtual machine sold as a service by an Internet hosting service. The virtual dedicated server (VDS) also works in a virtualized environment but some CPU cores are dedicated for it, which is not the case with VPS. A virtual private server runs its own copy of an operating system (OS), and customers may have superuser-level access to that operating system instance, so they can install almost any software that runs on that OS. For many purposes it is functionally equivalent to a dedicated physical server and, being software-defined, can much more easily be created and configured. A virtual server costs much less than an equivalent physical server. However, as virtual servers share the underlying physical hardware with other VPSes, performance may be lower, depending on the workload of any other executing virtual machines</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a Cloud Computing and why I should choose?</div>

                                    <div className="box-item-body"><p>Cloud computing is the on-demand availability of computer system resources, especially data storage (cloud storage) and computing power, without direct active management by the user. The term is generally used to describe data centers available to many users over the Internet. Large clouds, predominant today, often have functions distributed over multiple locations from central servers. If the connection to the user is relatively close, it may be designated an edge server. Clouds may be limited to a single organization (enterprise clouds), or be available to multiple organizations (public cloud). Cloud computing relies on sharing of resources to achieve coherence and economies of scale.</p></div>

                                </div>

                                <div role="contentinfo" className="box-item">

                                    <div className="box-item-header">What is a Dedicated Server and why I should choose?</div>

                                    <div className="box-item-body"><p>A dedicated hosting service, dedicated server, or managed hosting service is a type of Internet hosting in which the client leases an entire server not shared with anyone else. This is more flexible than shared hosting, as organizations have full control over the server(s), including choice of operating system, hardware, etc. There is also another level of dedicated or managed hosting commonly referred to as complex managed hosting. Complex Managed Hosting applies to both physical dedicated servers, Hybrid server and virtual servers, with many companies choosing a hybrid (combination of physical and virtual) hosting solution.</p></div>

                                </div>

                            </div>

                        </div>

                        <div className="content-right m-unset">

                            <span className="h2">List of questions</span>

                            <p>To help you make an informed decision, we've compiled a list of our frequently asked questions related to the services listed on this page.</p>

                            <p>If you cannot find the answer to your question or would like more information on the subject, you can visit our complete knowledge base or contact us at any time and we will be happy to assist you.</p>

                            <div className="btn-group">

                                <a className="btn btn-blue" href="/">Helpdesk</a>
                                <a className="btn btn-grey" href="/">Contact Us</a>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="container d-none">

                    <h5 className="h1">Third Title Page</h5>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi, dolore adipisicing elit. Nisi, dolore</p>

                    <div className="box box-info box-price mb-5">

                        <div className="box-item">

                            <span>Standard</span>

                            <p>Lorem, ipsum dolor sit</p>

                            <div className="price-value"><span>$</span><cite>10.00</cite><span>/month</span></div>

                            <p>Lorem, ipsum dolor sit Lorem dolor </p>

                            <a href="/" className="btn btn-blue">Add to cart</a>

                            <ul className="list-in-column">
                                <li><i className="cci-check-01"></i> Feature 01</li>
                                <li><i className="cci-check-01"></i> Feature 02</li>
                                <li><i className="cci-check-01"></i> Feature 03</li>
                                <li><i className="cci-check-01"></i> Feature 04</li>
                                <li><i className="cci-check-01"></i> Feature 05</li>
                                <li><i className="cci-uncheck-01"></i> Feature 06</li>
                                <li><i className="cci-uncheck-01"></i> Feature 07</li>
                                <li><i className="cci-uncheck-01"></i> Feature 08</li>
                            </ul>

                        </div>

                        <div className="box-item">

                            <span>Medium</span>

                            <p>Lorem, ipsum dolor sit</p>

                            <div className="price-value"><span>$</span><cite>20.00</cite><span>/month</span></div>

                            <p>Lorem, ipsum dolor sit Lorem dolor </p>

                            <a href="/" className="btn btn-blue">Add to cart</a>

                            <ul className="list-in-column">
                                <li><i className="cci-check-01"></i> Feature 01</li>
                                <li><i className="cci-check-01"></i> Feature 02</li>
                                <li><i className="cci-check-01"></i> Feature 03</li>
                                <li><i className="cci-check-01"></i> Feature 04</li>
                                <li><i className="cci-check-01"></i> Feature 05</li>
                                <li><i className="cci-uncheck-01"></i> Feature 06</li>
                                <li><i className="cci-uncheck-01"></i> Feature 07</li>
                                <li><i className="cci-uncheck-01"></i> Feature 08</li>
                            </ul>

                        </div>

                        <div className="box-item">

                            <span>Advanced</span>

                            <p>Lorem, ipsum dolor sit</p>

                            <div className="price-value"><span>$</span><cite>36.00</cite><span>/month</span></div>

                            <p>Lorem, ipsum dolor sit Lorem </p>

                            <a href="/" className="btn btn-blue">Add to cart</a>

                            <ul className="list-in-column">
                                <li><i className="cci-check-01"></i> Feature 01</li>
                                <li><i className="cci-check-01"></i> Feature 02</li>
                                <li><i className="cci-check-01"></i> Feature 03</li>
                                <li><i className="cci-check-01"></i> Feature 04</li>
                                <li><i className="cci-check-01"></i> Feature 05</li>
                                <li><i className="cci-uncheck-01"></i> Feature 06</li>
                                <li><i className="cci-uncheck-01"></i> Feature 07</li>
                                <li><i className="cci-uncheck-01"></i> Feature 08</li>
                            </ul>

                        </div>

                    </div>

                    <p className="mt-5">Lorem ipsum dolor sit amet, reprehenderit deleniti sapiente expedita et earum totam <a href="/">Contact Us</a></p>

                </div>

            </section>

            <Partners />
        </main>
    );
}

export default Contact;