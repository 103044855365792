export class ElementDrop {
    constructor(elementOpt) {
        this._globalOpt = elementOpt;
        this._activeClass = elementOpt.parentElActive.replace(/[., #]/g, "");
        if(elementOpt.parentElAnimation.active === true) this._animationClass = elementOpt.parentElAnimation.class.replace(/[., #]/g, "");
        if(elementOpt.active === true) this.init();
    };

    init() {

        document.querySelectorAll(this._globalOpt.buttonEl).forEach(buttonEl => {

            buttonEl.addEventListener("click", e=> {

                e.stopImmediatePropagation();

                this.bodyClick(e.target, e);

                this.dropElement(buttonEl.parentElement.querySelector(this._globalOpt.parentEl));

            });

        });

    };

    dropElement(parentEl) {

        const parentElement = parentEl;
        const elIsOpened = document.querySelector(this._globalOpt.parentElActive);

        if(parentEl === elIsOpened) {

            this.closeElement(elIsOpened);

            return false;

        };

        if(elIsOpened) {

            this.closeElement(elIsOpened);

        };

        if(parentElement !== null) {
            
            parentElement.classList.add(this._activeClass);

            parentEl.parentElement.setAttribute("aria-expanded", true);

            if(this._globalOpt.parentElAnimation) if(this._globalOpt.parentElAnimation.active === true) this.animation(true, parentElement);

        };

    };

    animation(active, parentEl) {

        const animationClass = this._globalOpt.parentElAnimation.class.replace(/[., "]/g, "");

        if(active) {

            setTimeout(() => {
           
                parentEl.classList.add(animationClass);
                
            }, this._globalOpt.parentElAnimation.time);

        } else {

            parentEl.querySelector(this._globalOpt.parentEl).classList.remove(animationClass);

        };

    };

    closeElement(parentEl) {

        if(this._globalOpt.parentElAnimation) {

            this.animation(false, parentEl.parentElement);

            if(this._globalOpt.parentElAnimation.closeAnimation.active === true) {

                setTimeout(() => {
                    
                    if(parentEl.classList.contains(this._activeClass)) {

                        parentEl.classList.remove(this._activeClass);
            
                    };

                    parentEl.parentElement.setAttribute("aria-expanded", false);

                }, this._globalOpt.parentElAnimation.closeAnimation.time);

            } else {

                parentEl.parentElement.setAttribute("aria-expanded", false);

            };

            return false;

        };

        if(parentEl.classList.contains(this._activeClass)) {

            parentEl.classList.remove(this._activeClass);

        };

    };

    bodyClick(buttonEl, event) {

        buttonEl.removeEventListener("click", event);

        document.addEventListener("click", e=> {

            e.stopImmediatePropagation();

            document.querySelectorAll(this._globalOpt.parentElActive).forEach(activeEl => {

                if(activeEl !== null) {
                    
                    this.closeElement(activeEl);
    
                };

            });

        });

    };

 }