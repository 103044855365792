import { appData } from "../../app_data";

const TermsOfService = () => {
    return (
        <main>

            <div className="thumb thumb-blue-dark">

                <div className="container justify-content-center">

                    <span className="h2 text-center">Terms and Conditions</span>

                </div>

            </div>

            <section aria-label="Terms of Service">

                <div className="container align-items-start document">

                    <span className="h2">1. Acceptance of Terms These Terms of Service</span>

                    <span>(“Terms”) constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you” or “your”) and [{appData.appName}] (“{appData.appName}”, “we”, “us”, or “our”), concerning your access to and use of the web hosting services as offered by us (the “Services”). You agree that by accessing or using the Services, you are indicating that you have read, understand, and agree to be bound by these Terms. If you do not agree with these Terms, then you are prohibited from accessing or using the Services. </span>

                    <span className="h2">2. Provision of Services</span>
                    <span>{appData.appName} agrees to provide you with web hosting services which includes, but is not limited to, the provision of hosting space for your website and access to various features including, but not limited to, email, databases, and web statistics. </span>

                    <span className="h2">3. Responsibility and Obligations</span>
                    <span>You are solely responsible for your own content and information that you upload, post, or otherwise transmit via the Services. You agree to use the Services only for lawful purposes, and in compliance with all applicable laws, rules, and regulations. You agree not to use the Services to upload, post, or otherwise transmit any content or information that:
                        <ul>
                            <li>Is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or invasive of another’s privacy;</li>
                            <li>Infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party; </li>
                            <li>Constitutes unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;</li>
                            <li>Contains software viruses or any other computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of any third party; </li>
                            <li>Impersonates any person or entity, or falsely states or otherwise misrepresents your affiliation with a person or entity; </li>
                            <li>Violates any applicable local, state, national, or international law. You are solely responsible for any content or information you upload, post, or otherwise transmit via the Services, and you agree to indemnify and hold {appData.appName} harmless from any and all claims, losses, liabilities, damages, and expenses (including reasonable attorneys’ fees) arising out of or related to your use of the Services or your violation of these Terms.</li>
                        </ul>
                    </span>

                    <span className="h2">4. Limitation of Liability </span>
                    <span>{appData.appName} shall not be liable for any damages, including without limitation, direct, indirect, incidental, special, punitive, and consequential damages, arising out of or related to the use of or inability to use the Services, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses. </span>

                    <span className="h2">5. Termination </span>
                    <span>You agree that {appData.appName} may terminate your access to the Services, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of these Terms which by their nature should survive termination shall survive.</span>

                    <span className="h2">6. Changes to Terms </span>
                    <span>{appData.appName} reserves the right to modify these Terms at any time, and such modifications shall be effective once posted on the website. You agree to review these Terms periodically, and your continued access or use of the Services shall be deemed your acceptance of the modified Terms.
                    </span>

                    <span className="h2">7. Miscellaneous </span>
                    <span>These Terms constitute the entire agreement between you and {appData.appName} concerning the Services and supersedes all prior or contemporaneous understandings or agreements, whether written or oral, regarding the Services. No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is held invalid, the remainder of these Terms shall continue in full force and effect. The section titles in these Terms are solely used for the convenience of the parties and have no legal or contractual significance. </span>

                    <span className="h2">8. Contact Information </span>
                    <span>If you have any questions or comments about these Terms, please contact us at {appData.supportEmail}.</span>



                    
                </div>

            </section>

        </main>
    );
}

export default TermsOfService;